// container
.skltbs-theme-light {
    color: $color-light-text;

    .skltbs {
        // tabs
        &-tab {
            background: $color-light-fade;
            border: 3px solid $color-light-fade;
            color: fade-out($color-light-text, 0.5);

            // focused tab
            &:focus {
                outline: 0;
                border-color: $darkgray;
            }

            // disabled tabs
            &:disabled {
                color: lighten($color-light-text, 60);
                background: transparent;
            }

            // active tab
            &.skltbs-active {
                background: $color-light-accent;
                color: $color-light-text;

                &:focus,
                &:hover {
                    //border-color: lighten($color-light-accent, 10);
                }
            }
        }

        // contents to toggle
        &-panel {
            background-color: $color-light-background;
            //border: 2px solid $color-light-fade;
            border: none;

            &:focus {
                outline: 0;
                //border-color: lighten($color-light-accent, 15);
            }
        }
    }
}
