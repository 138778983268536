.nav-header-wrapper {
  width: 100%;
  z-index: 101;
  border-bottom: 1px solid #bebebe;
  background: $white;
  position: fixed;
  top: 0;

  &.initialized {
    transition: background-color 0.2s ease, color 0.2s ease;
  }

  // header white
  .page-layout-0 & {
    @include desktop-nav() {
      background: $white;
      color: $textcolor;
    }
  }
  // header transparent
  .page-layout-1 & {
    @include desktop-nav() {
      background: transparent;
      color: $white;
    }
  }

  &.tiny {
    z-index: 102;

    @include desktop-nav() {
      background: $white;
      color: $textcolor;
    }
  }
}

.nav-header {
  display: flex;
  align-items: center;
  max-width: $container-width;
  margin: auto;
  position: relative;
  padding: calc(#{$frame-padding}/2) $frame-padding;

  .initialized > & {
    transition: padding 0.2s ease;
  }

  @include desktop-nav() {
    padding: 56px $frame-padding 0;

    .tiny > & {
      padding: 16px $frame-padding 0;
    }
  }

  .brand {
    @extend h1;
    order: 0;
    margin-top: 0;
    margin-bottom: 0;

    margin-right: auto;
    height: auto;
    line-height: 1;
    max-width: 160px;
    width: 100%;
    align-items: center;
    display: flex;

    @include desktop-nav() {
      max-width: 220px;
    }

    svg {
      max-height: 40px;
      max-width: 100%;
    }
  }

  .nav-toggler {
    order: 1;
  }

  .navbar-nav {
    z-index: 10;
  }

}

