$middlegray: #949EA6;

.frame-layout {
    &-dark-gray {
        background: $middlegray;

        svg {
            color: $turquoise;
        }
    }

    &-light-gray {
        background: rgba($lightgray, 0.5);

        svg {
            color: $turquoise;
        }
    }


    &-bluetrans-light {
        color: $white;
        background: rgba($blueblack, 0.6);

        svg {
            color: $white;
        }

        a {
            color: $primary;
            text-decoration: none;
        }
    }
}
