.product-container {
    padding-top: var(--header-height, 72px);

    @include desktop-nav() {
        padding-top: var(--header-height, 150px);
    }

    .product-image {
        text-align: center;

        svg, img {
            min-width: 60%;
            height: auto;

            @include desktop-nav() {
                min-width: 80%;
            }
        }
    }

    .docu-iframe-container {
        width: 100%;
        max-width: none;

        .iframe {
            padding: 3rem 0;

            &:has(+ .download-pdf) {
                padding-bottom: 1rem;
            }

            iframe {
                min-height: 75vh;
            }
        }

        .download-pdf {
            margin-bottom: 3rem;
        }
    }
}



