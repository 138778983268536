.frame.ce-breadcrumb {
    font-size: 1rem;
    margin-bottom: 1rem;

    $icon-path: "../Icons";

    a {
        text-decoration: underline;

        &:first-child {
            &:before {
                display: inline-block;
                content: "";
                background-image: url(#{$icon-path}/pictograms/css/home.svg);
                background-size: contain;
                width: 1rem;
                height: 1rem;
                position: relative;
                top: 2px;
                color: inherit;
                border-right: 4px solid $white;
                margin-right: -3px;
            }
        }
    }
    .menu-item {

        &.current-page {
            font-weight: $font-medium;
        }
    }
}
