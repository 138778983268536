.tx_solr {
    .col-md-12 {
        width: 100%;
    }
}

.tx_solr.grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 1rem 1rem;
    justify-items: stretch;
    align-items: stretch;
    grid-template-areas:
    "results results results facets";

    @include desktop-nav() {
        display: grid;
    }
}

#tx-solr-search {
    grid-area: results;
}

#tx-solr-search-functions {
    grid-area: facets;
}

.tx-solr-search-form {
    position: relative;

    .tx-solr-submit {
        background: transparent;
        position: absolute;
        width: 2rem;
        min-width: fit-content;
        top: 0;
        left: 0;

        .search-icon {
            cursor: pointer;
            background-color: $black;
            mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="2"><circle cx="9" cy="9" r="7"/><path d="M21 22l-6-6"/></svg>');
            -webkit-mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="2"><circle cx="9" cy="9" r="7"/><path d="M21 22l-6-6"/></svg>');
            mask-size: cover;
            -webkit-mask-size: cover;
            width: 40px;
            height: 40px;
            display: block;
        }
    }

    .tx-solr-q {
        min-height: 80px;
        border: none;
        border-radius: 0.5rem;
        background: rgba($white, 0.75);
        padding: 1.2rem 3rem 1rem 6rem;
        font-weight: $font-medium;
    }
}

.tx-solr-autosuggest {
     border: none;
     margin-left: 6rem;

    .autocomplete-selected {
        background-color: $lightgray;
    }
 }

.tx-solr-autosuggest .autocomplete-suggestion {
    cursor: pointer;
    padding: 0.5rem 1rem;
}

.tx-solr-autosuggest strong {
    color: $green;
}

#tx-solr-faceting {
    @include desktop-nav() {
        max-height: 999em;
    }
}

#tx-solr-search {
    a {
        text-decoration: none;
    }

    .result-range {
        font-size: 12px;
    }

    #tx-solr-facets-in-use {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        #tx-solr-facets-desc {
            margin-right: 0.5rem;
        }

        .reset {
            margin: 0 0.75rem;
        }
    }

    // TODO: move to extra file?
    // TODO: merge with document-search.scss
    &.compatible {

        .search-result {
            background: $white;
            //border: 1px solid rgba($textcolor, 0.15);
            box-shadow: 0 3px 6px $gray;
            font-size: $fs-xs;
            margin-top: 1rem;

            .compatible-result-image {
                text-align: center;
                background: rgba($darkgray, 0.1);
                height: 135px;
                margin-bottom: 0.5rem;
                overflow: hidden;

                img {
                    display: block;
                    height: 100%;
                    object-fit: contain;
                    padding: 5% 0;
                    width: 100%;
                }

                svg {
                    max-height: 100%;
                    padding: 5% 0;
                    object-fit: contain;
                }
            }

            .results-topic {
                padding: 0 0.5rem;
                margin-bottom: 1rem;

                .compatible-manufacturer {
                    font-weight: 600;
                }
            }

            .results-teaser {
                padding: 0 0.5rem 0.75rem 0.5rem;

                span {
                    &:after {
                        content: ' | ';
                    }
                    &:last-child:after {
                        content: '';
                    }
                }

            }
        }
    }

    &.documentsearch{

        .documentsearch-intro {
            margin-top: 20px;
            color: #566773
        }

        .search-result {
            font-size: $fs-s;
            margin-top: 0.75rem;
            margin-bottom: 0.75rem;

            .results-topic {
                .documentsearch-heading {
                    font-size: 18px;
                    font-weight: 600;
                }
            }

            .results-teaser {
                padding: 0 0.25rem 0.5rem 0.25rem;
                display: flex;
                flex-wrap: wrap;
            }

            .facet-option {

                .btn--facet {
                    font-size: 12px;
                    font-weight: 300;
                    min-width: auto;
                    pointer-events: none;
                }
            }
        }
    }

    .solr-pagination {
        display: flex;
        align-items: center;
        justify-content: center;

        ul {
            padding: 0;

            li {
                list-style: none;
                display: inline-block;
                padding: 0 0.5rem;

                &.active {
                    font-weight: bold;
                }
            }
        }
    }
}

#tx-solr-search-functions {

    a {
        text-decoration: none;
    }

    .tx-solr-facet-show-all {
        display: block;
        font-size: $fs-xs;
        text-decoration: underline;
        min-width: 12.5rem;
        min-height: 2.375rem;
        line-height: 2.375rem;
        margin: 0.2rem 0.375rem 0.2rem 0;
    }

    #tx-solr-facet-switch {
        color: $white;
        background: $mint;
        display: flex;
        align-items: center;
        min-height: 60px;
        padding: 0.875rem;
        font-weight: $font-medium;
        justify-content: center;
        border: none;
        cursor: pointer;
        outline: inherit;
        width: 100%;

        svg {
            color: $white;
            margin-left: 1rem;
        }

        &.active {
            color: $mint;
            background: rgba($gray, 0.5);

            svg {
                color: $mint;
            }
        }

        @include desktop-nav() {
            color: $mint;
            cursor: default;
            background: rgba($gray, 0.5);
            justify-content: space-between;

            svg {
                color: $mint;
                margin-left: 0;
            }
        }
    }

    .solr-facets-available {
        border: 1px solid rgba($darkgray, 0.5);
        padding: 0.5rem 0.5rem 0.75rem 0.5rem;

        ul {
            padding: 0;

            li {
                list-style: none;
            }
        }

        .card {
            width: 100%;
        }

        .facet-label {
            margin-bottom: 0.5rem;

            button {
                background: none;
                color: inherit;
                border: none;
                padding: 0;
                font: inherit;
                cursor: pointer;
                outline: inherit;
                text-transform: capitalize;
                font-weight: $font-medium;
                width: 100%;
                text-align: left;
                position: relative;

                &:after {
                    content: "";
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='currentColor'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
                    background-position: right center;
                    background-repeat: no-repeat;
                    position: absolute;
                    right: 0.5rem;
                    height: 100%;
                    width: 1.5rem;
                    transition: all .3s ease-in-out;
                }

                &.active {
                    &:after {
                        transform: scaleY(-1);
                    }
                }
            }
        }

        .facet-type-options {
            display: flex;
            flex-wrap: wrap;
        }
    }
}
