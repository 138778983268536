
.frame-layout-dark-gray {

    header {
        padding: 0 0.5rem;
        text-align: center;
        margin-bottom: 0.5rem;

        @include tablet() {
            text-align: left;
        }

        h3 {
            font-size: $fs-sm;
            font-weight: 600;
        }
    }


    .input {

        textarea,
        .select-styled,
        .select-options li,
        input[type=date],
        input[type=url],
        input[type=number],
        input[type=password],
        input[type=tel],
        input[type=email],
        input[type=text] {
            background: transparent;
            border: 1px solid $white;
        }

        input[type=date],
        input[type=url],
        input[type=number],
        input[type=password],
        input[type=tel],
        input[type=email],
        input[type=text] {
            height: 3rem;
        }

        textarea,
        input[type=date],
        input[type=url],
        input[type=number],
        input[type=password],
        input[type=tel],
        input[type=email],
        input[type=text] {

            &::placeholder {
                top: 0;
                font-size: $fs-sm;
            }

            &:focus::placeholder {
                transform: translate(0, -2.5rem);
            }
        }
    }

    form {
        .form-navigation {
            text-align: center;

            @include tablet() {
                text-align: right;
            }

            .btn.btn-primary {
                font-size: $fs-sm;
                min-height: 56px;
            }
        }
    }
}
