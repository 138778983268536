main {
    // teaser image
    .page-layout-1 & > [class*='frame-full-height']:first-child,
    .page-layout-1 & > [class*='frame-']:not(.frame-full-height):first-child > .frame-full-height:first-child {

        // gradient
        &:not([data-svg-background]):before {
            pointer-events: none;
        }
    }
}

.d-grid {
    display: grid !important;
}

.container_3_columns {
    @include tablet() {
        grid-template-columns: 1fr 1fr;
    }
    @include desktop-nav() {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.container_4_columns {
    @include tablet() {
        grid-template-columns: 1fr 1fr;
    }
    @include large() {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

.gap-1 {
    gap: 1rem;
}

.collapse {
    display: block;
    max-height: 0;
    overflow: hidden;
    transition: max-height .3s cubic-bezier(0, 1, 0, 1);
    &.show {
        max-height: 99em;
        transition: max-height .3s ease-in-out;
    }

    &:not(.show) {
        display: none;
    }
}
