.news-search-form {
  position: relative;
  overflow: hidden;

  .js-search-open {
    cursor: pointer;
    background-color: $black;
    mask-image: url(/fileadmin/pictograms/css/Search.svg);
    -webkit-mask-image: url(/fileadmin/pictograms/css/Search.svg);
    mask-size: cover;
    -webkit-mask-size: cover;
    width: 30px;
    height: 30px;
    display: block;
  }

  form {
    position: absolute;
    width: auto;
    left: 30px;
    transform: translate(calc(-100% - 30px));
    transition: all .8s ease;
  }

  input#news-subject {
    border: 0;
    border-bottom: 2px solid;
    height: 30px;
  }

  .no-js form,
  &.open form {
    transform: translate(0);
  }

  label,
  .form-group:nth-child(2),
  .form-group:nth-child(3),
  .btn {
    display: none;
  }

  fieldset {
    display: flex;
  }
}
