/*! copyright @ xerc.de */

$grid-base  : calc( 1 / 12 );

$grid-col-1 : #{$grid-width *  1};
$grid-col-2 : #{$grid-width *  2};
$grid-col-3 : #{$grid-width *  3};
$grid-col-4 : #{$grid-width *  4};
$grid-col-5 : #{$grid-width *  5};
$grid-col-6 : #{$grid-width *  6};
$grid-col-7 : #{$grid-width *  7};
$grid-col-8 : #{$grid-width *  8};
$grid-col-9 : #{$grid-width *  9};
$grid-col-10: #{$grid-width * 10};
$grid-col-11: #{$grid-width * 11};

.row {
  max-width: $container-width;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;

  &.row-fluid {
    max-width: none;
    flex-direction: column;
  }
}

.row [class*="col-"] {
  margin: 0.5rem 0;
  padding: 0;
  min-height: 0.125rem;
  flex-grow: 0;
  flex-shrink: 0;

  &:empty {
    padding: 0;
    margin: 0;
    min-height: 0;
  }
}

.col-12,
.col-12-sm,
.col-12-md,
.col-12-lg {
  width: $grid-width;
}

.col-1-sm { width: calc(#{$grid-base} * (#{$grid-col-1} - (11 * var(--grid-gutter)))); }
.col-2-sm { width: calc(#{$grid-base} * (#{$grid-col-2} - (10 * var(--grid-gutter)))); }
.col-3-sm { width: calc(#{$grid-base} * (#{$grid-col-3} -  (9 * var(--grid-gutter)))); }
.col-4-sm { width: calc(#{$grid-base} * (#{$grid-col-4} -  (8 * var(--grid-gutter)))); }
.col-5-sm { width: calc(#{$grid-base} * (#{$grid-col-5} -  (7 * var(--grid-gutter)))); }
.col-6-sm { width: calc(#{$grid-base} * (#{$grid-col-6} -  (6 * var(--grid-gutter)))); }
.col-7-sm { width: calc(#{$grid-base} * (#{$grid-col-7} -  (5 * var(--grid-gutter)))); }
.col-8-sm { width: calc(#{$grid-base} * (#{$grid-col-8} -  (4 * var(--grid-gutter)))); }
.col-9-sm { width: calc(#{$grid-base} * (#{$grid-col-9} -  (3 * var(--grid-gutter)))); }
.col-10-sm{ width: calc(#{$grid-base} * (#{$grid-col-10} - (2 * var(--grid-gutter)))); }
.col-11-sm{ width: calc(#{$grid-base} * (#{$grid-col-11} - (1 * var(--grid-gutter)))); }

@media only screen and (min-width: $screen-sm) {
  .col-1-md,
  .col-1 { width: calc(#{$grid-base} * (#{$grid-col-1} - (11 * var(--grid-gutter)))); }
  .col-2-md,
  .col-2 { width: calc(#{$grid-base} * (#{$grid-col-2} - (10 * var(--grid-gutter)))); }
  .col-3-md,
  .col-3 { width: calc(#{$grid-base} * (#{$grid-col-3} -  (9 * var(--grid-gutter)))); }
  .col-4-md,
  .col-4 { width: calc(#{$grid-base} * (#{$grid-col-4} -  (8 * var(--grid-gutter)))); }
  .col-5-md,
  .col-5 { width: calc(#{$grid-base} * (#{$grid-col-5} -  (7 * var(--grid-gutter)))); }
  .col-6-md,
  .col-6 { width: calc(#{$grid-base} * (#{$grid-col-6} -  (6 * var(--grid-gutter)))); }
  .col-7-md,
  .col-7 { width: calc(#{$grid-base} * (#{$grid-col-7} -  (5 * var(--grid-gutter)))); }
  .col-8-md,
  .col-8 { width: calc(#{$grid-base} * (#{$grid-col-8} -  (4 * var(--grid-gutter)))); }
  .col-9-md,
  .col-9 { width: calc(#{$grid-base} * (#{$grid-col-9} -  (3 * var(--grid-gutter)))); }
  .col-10-md,
  .col-10{ width: calc(#{$grid-base} * (#{$grid-col-10} - (2 * var(--grid-gutter)))); }
  .col-11-md,
  .col-11{ width: calc(#{$grid-base} * (#{$grid-col-11} - (1 * var(--grid-gutter)))); }

  .row .offset-1 { margin-left: calc(#{$grid-base} * (#{$grid-col-1} - (11 * var(--grid-gutter)))); }
  .row .offset-2 { margin-left: calc(#{$grid-base} * (#{$grid-col-2} - (10 * var(--grid-gutter)))); }
  .row .offset-3 { margin-left: calc(#{$grid-base} * (#{$grid-col-3} -  (9 * var(--grid-gutter)))); }
  .row .offset-4 { margin-left: calc(#{$grid-base} * (#{$grid-col-4} -  (8 * var(--grid-gutter)))); }
  .row .offset-5 { margin-left: calc(#{$grid-base} * (#{$grid-col-5} -  (7 * var(--grid-gutter)))); }
  .row .offset-6 { margin-left: calc(#{$grid-base} * (#{$grid-col-6} -  (6 * var(--grid-gutter)))); }
  .row .offset-7 { margin-left: calc(#{$grid-base} * (#{$grid-col-7} -  (5 * var(--grid-gutter)))); }
  .row .offset-8 { margin-left: calc(#{$grid-base} * (#{$grid-col-8} -  (4 * var(--grid-gutter)))); }
  .row .offset-9 { margin-left: calc(#{$grid-base} * (#{$grid-col-9} -  (3 * var(--grid-gutter)))); }
  .row .offset-10{ margin-left: calc(#{$grid-base} * (#{$grid-col-10} - (2 * var(--grid-gutter)))); }
  .row .offset-11{ margin-left: calc(#{$grid-base} * (#{$grid-col-11} - (1 * var(--grid-gutter)))); }
}

@media only screen and (min-width: $screen-md) {
  .col-1-lg { width: calc(#{$grid-base} * (#{$grid-col-1} - (11 * var(--grid-gutter)))); }
  .col-2-lg { width: calc(#{$grid-base} * (#{$grid-col-2} - (10 * var(--grid-gutter)))); }
  .col-3-lg { width: calc(#{$grid-base} * (#{$grid-col-3} -  (9 * var(--grid-gutter)))); }
  .col-4-lg { width: calc(#{$grid-base} * (#{$grid-col-4} -  (8 * var(--grid-gutter)))); }
  .col-5-lg { width: calc(#{$grid-base} * (#{$grid-col-5} -  (7 * var(--grid-gutter)))); }
  .col-6-lg { width: calc(#{$grid-base} * (#{$grid-col-6} -  (6 * var(--grid-gutter)))); }
  .col-7-lg { width: calc(#{$grid-base} * (#{$grid-col-7} -  (5 * var(--grid-gutter)))); }
  .col-8-lg { width: calc(#{$grid-base} * (#{$grid-col-8} -  (4 * var(--grid-gutter)))); }
  .col-9-lg { width: calc(#{$grid-base} * (#{$grid-col-9} -  (3 * var(--grid-gutter)))); }
  .col-10-lg{ width: calc(#{$grid-base} * (#{$grid-col-10} - (2 * var(--grid-gutter)))); }
  .col-11-lg{ width: calc(#{$grid-base} * (#{$grid-col-11} - (1 * var(--grid-gutter)))); }
}
