.stikky {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 0;
  padding: .4rem;
  cursor: pointer;
  z-index: 10;
  color: $white;
  background-color: $mint;
  opacity: 1;
  transition: opacity 0.2s ease;
  z-index: 99;

  @include hover {
    opacity: .8;
  }

  @include desktop-nav {
    z-index: 102;
  }

  &.mailform {
    top: 25vh;

    img, svg {
      width: 30px;
      height: 25px;
      color: $white;
    }

    @include desktop {
      top: 28vh;
    }

    @include large {
      top: 2vh;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      width: 100%;
      height: 100%;
    }
    .icon-wrapper {
      position: relative;
      transform: scale(2.1);
    }

    .icon {
      color: $white;
    }
  }
}
