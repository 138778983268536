/*! copyright @ xerc.de */

html,
body {
  padding: 0;
  margin: 0;
  color: black;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  font-size: $fs-standard;
  font-family: $main-font;
  line-height: 1.5;
  font-weight: $font-normal;
  position: relative;
}

* {
  box-sizing: border-box;
  outline: 0;
}

a {
  cursor: pointer;
  transition: color 0.2s ease;
}

h1,h2,h3,h4,h5,h6 {
  font-family: $headline-font;
  font-weight: $font-bolder;
}

h1 {
  font-size: $fs-xxl;
}
h2 {
  font-size: $fs-xl;
}
h3 {
  font-size: $fs-l;
}
h4 {
  font-size: $fs-m;
}
h5 {
  font-size: $fs-sm;
}
h6 {
  font-size: $fs-s;
}

dt,
dd {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
}

form {
  margin: 0;
}

figure {
  margin: 0;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

iframe {
  border: 0;
}

svg,symbol {
  max-width: 100%;
  height: auto;
  overflow: visible;
}

canvas {
  max-width: 100%;
}

textarea,
input[type=url],
input[type=number],
input[type=password],
input[type=tel],
input[type=email],
input[type=text] {
  width: 100%;
}

textarea {
  resize: vertical;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}
