// Customization: News Detail CE text-image
// besides no-wrap as 50/50 grid

.news-single {

  .frame {

    .ce-textpic.ce-intext.ce-nowrap {

      &.ce-right,
      &.ce-left {
        @extend .row;
        --grid-gutter: 1rem;

        .ce-gallery {
          margin-right: 0;
          margin-left: 0;
        }

        .ce-gallery,
        .ce-column {
          float: none;
        }

        .ce-gallery,
        .ce-bodytext {
          @extend .col-12-sm;
          @extend .col-6-lg;
        }
      }

      &.ce-left {

        .ce-bodytext {
          order: -1;
        }
      }
    }
  }
}
