footer {
  color: $white;
  background-color: $blueblack;
  font-size: $fs-xs;
  padding: 4rem 0 3em 0;

  .ce-textpic,
  .ce-textpic .ce-gallery,
  .ce-textpic .ce-gallery .ce-row {
    overflow: visible;
  }

  // socials
  // TODO: is this used somewhere?
  //[frame-layout-*-icon] svg {
  //  width: 36px;
  //  height: 36px;
  //  padding: .25rem .5rem;
  //  margin: 0 .25rem;
  //  transition: transform 0.2s ease;
  //  &:hover {
  //    transform: scale(1.05);
  //  }
  //}

  // copyright
  .ce-bodytext {

    @include tablet-max() {
      font-size: $fs-xxs;
    }
  }

  p {
    margin: 0;
  }
  .ce-gallery {
    margin-bottom: 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin-left: -.75rem;
    margin-right: -.75rem;

    @include tablet() {
      display: flex;
      align-items: center;
    }

    li {
      a {
        display: block;
        color: $white;
        padding: .25rem .75rem;
        text-decoration: none;

        &:after {
            content: "";
            display: block;
            border-top: 2px solid $green;
            width: 0;
            top: 100%;
            position: relative;
            transition: width 0.2s ease;
            background: transparent;
            margin: 0;
            height: auto;
            left: 0;
            mask-image: none;
        }

        @include desktop-nav() {
          &:hover:after {
            width: 100%;
          }
        }
      }
    }
  }

  .row [class*="col-"] {
    margin: 0;
  }

  // second row
  .row + .row {
    // left col
    > .col-6-lg:first-child {
      @include tablet-max() {
        order: 2;
      }
    }
  }

  // right col
  .col-6-lg + .col-6-lg {

    // no wrap
    .ce-left .ce-gallery, .ce-column { float: left; }
    .ce-right .ce-gallery { float: right; }
  }

    .cookie-consent-settings {
        width: max-content;

        .cookie-consent-open.btn {
            background: transparent;
            padding:0;
            font-size:inherit;
            min-height: auto;
            min-width: max-content;
            font-weight: 400;
            text-align: left;
        }
    }

    a, h3 a, h4 a {
        display: inline-block;
        text-decoration: none;
    }

    p a[href^=https]:after {display: none;}

    a , h3 a, h4 a, ul li a, .cookie-consent-settings, p a[href^=https] {
        &:after {
            content: "";
            display: block;
            border-top: 2px solid $green;
            width: 0;
            top: 100%;
            position: relative;
            transition: width .2s ease;
            background: transparent;
            margin: 0;
            height: auto;
            left: 0;
            mask-image: none;
        }
    }

    @media (min-width: 920px) {
        a , h3 a, h4 a, ul li a, .cookie-consent-settings, p a[href^=https] {
            &:hover:after {
                width: 100%;
            }
        }
    }

    h3, h4 {
        font-weight: $font-medium;
        margin-bottom: 1rem;
    }

    p {
        margin: 0;
        margin-bottom: 0.35rem;
    }
}
