// colors
$black:       #000;
$white:       #fff;
$lightgray:   #efefef;
$gray:        #dfdfdf;
$darkgray:    #32404f;
$mint:        #11b09b;
$turquoise:   #00818b;//kiwi DOTS | #006a72
$green:       #c7cc00;//kiwi ICON
$red:         #cc0000;
$blueblack:   #2e4050;//kiwi TEXT

$textcolor:   $blueblack;
$default:     $lightgray;
$primary:     $mint;
$secondary:   $white;

// brand colors from Arnu
$success: #6fbc85;
$warning: #f59d21;
$error: #e86559;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio:   4.5 !default;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark:  $black !default;
$color-contrast-light: $white !default;

// fonts
$main-font:     'Anago', sans-serif;
$headline-font: 'Anago', sans-serif;

$font-bolder:   800;
$font-bold:     700;
$font-medium:   500;
$font-normal:   400;

// spaces
$space-l:       3.5rem;
$space-m:       1.813rem;
$space-sm:      1.5rem;
$space-s:       1rem;
$space-xs:      .625rem;

// fontsizes
$fs-xxl:        3rem;
$fs-xl:         2.625rem;
$fs-l:          1.875rem;
$fs-m:          1.375rem;
$fs-sm:         1.125rem;
$fs-standard:   1.125rem;
$fs-s:          1rem;
$fs-xs:          .875rem;
$fs-xxs:         .625rem;

// breakpoints
$screen-xs:   450px;
$screen-sm:   768px;
$screen-md:   920px;
$screen-lg:  1280px;

$mobile-nav-breakpoint-min:   $screen-md;

$tablet-breakpoint-min:       $screen-sm;
$desktop-breakpoint-min:      $screen-md;
$large-breakpoint-min:        $screen-lg;

$container-width:             68.25rem;
$container-width--slim:       60rem;
$container-width--slimmer:    45rem;
$container-padding:           $space-m;
$container-padding--desktop:  $space-l;
$frame-padding:               $space-m;

// grid
$grid-width:                  100%;
// css crush strips unit from 0 (px,em,rem), breaks calc --> 0%
$grid-gutter:                   0%;

:root {
  --grid-gutter: #{$grid-gutter};
}

// transitions
$transition: all .2s ease-in-out;
