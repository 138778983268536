.form-group {
  padding: 0 .5rem .5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .control-label {
    display: none;
  }
}

.form-navigation {
  text-align: center;
}

form {
  [class*="col-"] {
    padding: 0 1rem;
  }
}

.input {

  textarea,
  .select-styled,
  .select-options li,
  input[type=date],
  input[type=url],
  input[type=number],
  input[type=password],
  input[type=tel],
  input[type=email],
  input[type=text] {
    border: 2px solid $white;
    padding: .75rem;
    width: 100%;
    font-family: $main-font;
    line-height: 1.6;
    font-weight: $font-normal;
    background: $lightgray;
    border: 1px solid $lightgray;
  }

  input[type=date],
  input[type=url],
  input[type=number],
  input[type=password],
  input[type=tel],
  input[type=email],
  input[type=text] {
    height: 4rem;
  }

  textarea,
  input[type=date],
  input[type=url],
  input[type=number],
  input[type=password],
  input[type=tel],
  input[type=email],
  input[type=text] {

    &::placeholder {
      color: $darkgray;
      position: absolute;
      top: -.5rem;
      left: 0;
      padding: .75rem;
      font-size: $fs-xs;
      opacity: 1;
      transition: all 0.3s ease;
    }

    &:focus::placeholder {
      opacity: 0;
      transform: translate(0, -1rem);
    }
  }

  input[type=radio],
  input[type=checkbox] {
    background: transparent;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    background: $lightgray;
    margin-right: 1rem;
    position: relative;
    flex-shrink: 0;
    border: 1px solid $lightgray;

    &:checked {
      background: $black;
    }
  }

  input[type=checkbox] {
    &:before {
      content: "";
      position: absolute;
      height: 10px;
      width: 4px;
      left: 7px;
      top: 2px;
      box-sizing: content-box;
      border: 2px solid $darkgray;
      border-left: 0;
      border-top: 0;
      transition: transform .15s ease-in .05s,-webkit-transform .15s ease-in .05s;
      transform-origin: center;
      transform: rotate(45deg) scaleY(0);
    }

    &:checked {
      background: $lightgray;
    }

    &:checked:before {
      transform: rotate(45deg) scaleY(1);
    }
  }


  .inputs-list .form-group {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > * {
      flex: 0 0 33.3%;
    }
  }

  .radio,
  .form-check {

    label {
      display: flex;
      align-items: center;
      margin-right: 2rem;
      font-size: $fs-xs;
      cursor: pointer;
      flex-wrap: wrap;

      span {
        font-weight: $font-normal;
        max-width: 90%;
      }
    }
  }

  .form-check {
    label {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
    }
  }

  &.checkbox {

    .inputs-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      > * {
        flex: 0 0 33.3%;
      }
    }
  }


  .select {
    position: relative;
    height: 100%;

    &-hidden {
      display: none;
    }

    &-styled {
      display: flex;
      align-items: center;
      background-color: $lightgray;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;

      &__toggle {
        position: absolute;
        right: 15px;
        top: calc(50% - 6px);
        width: 10px;
        height: 13px;
        transition: transform 0.2s ease;

        &:after {
          content: "";
          position: absolute;
          border: solid $black;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 4px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          right: 0;
          top: 0;
        }
      }

      &.active ~ .select-styled__toggle {
        transform: rotateX(180deg);
      }
    }

    &-options {
      display: none;
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      margin: 0;
      padding: 0;
      text-align: left;
      list-style: none;
      background-color: $lightgray;
      border-bottom: 1px solid darken(white, 10);
      z-index: 1;
      max-height: 250px;
      max-height: 50vh;
      overflow: scroll;
      cursor: pointer;

      &__item,
      li {
        display: flex;
        align-items: center;
        margin: 0;
        border-top: 1px solid darken(white, 10);

        &:hover {
          background: darken(white, 10);
        }
      }
    }
  }
}

.error-message {
  font-size: $fs-xs;

  .checkbox & {
    display: none;
  }
}

.has-error .error > textarea,
.has-error .error > input,
.has-error .error + .chosen-container > .chosen-single,
.has-error .select-styled:not(.select-hidden) {
  border-color: #de0000;
  color: #de0000;
}

.has-error .checkbox .error > span,
.has-error .error > textarea::placeholder,
.has-error .error > input::placeholder,
.has-error .error-message {
  color: #de0000;
}
.error.help-block {
  display: none;
}
