.cc-window,
.cc-banner {

  .cc-message,
  .cc-compliance {

    .cc-btn {
      min-height: 0;
      min-width: 0;
      font-size: $fs-xs;
      font-weight: bold;

      &.cc-allow,
      &.cc-deny {
        color: $mint;
      }
    }
  }
}
