/*! copyright @ xerc.de */

.frame {
  padding-left: $frame-padding;
  padding-right: $frame-padding;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  z-index: 0;

  &-default {
    max-width: $container-width;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  &-slim {
    max-width: $container-width--slim;
  }

  &-vbottom {
    align-items: flex-end;
  }

  &-vcenter {
    align-items: center;
  }

  &-eqheight {
    > [class*="col-"] > * {
      height: 100%;
    }
  }
}