.ce-product-tile {

    a {
        text-decoration: none;
    }

    .product-tile-container {
        background: $lightgray;
        min-height: 18rem;
        color: $white;
        position: relative;
        background-size: cover;
        background-position: center;

        .product-tile-text-bar {
            background: rgba($blueblack, 0.9);
            position: absolute;
            bottom: 0;
            width: 100%;
            min-height: 45%;
            text-align: center;

            @include tablet() {
                text-align: left;
            }

            .product-tile-icon {
                display: flex;
                height: 100%;
                align-items: center;
                justify-content: center;
                padding: 1rem;

                img, svg {
                    @media (min-width: $screen-md) {
                        height: 80px;
                        max-width: 100%;
                        width: auto;
                    }
                }
            }

            .product-tile-title {
                font-weight: 600;
                font-size: $fs-l;
                line-height: 1.1;
                margin: 0.5rem 1rem 0 1rem;

                @include tablet() {
                    margin: 0.75rem 1rem 0 0;
                }
            }

            .product-tile-subtitle {
                margin: 0.75rem 1rem 0 1rem;
                line-height: 1.2;

                @include tablet() {
                    margin: 0.75rem 1rem 0 0;
                }
            }

            .product-tile-tileTitle {
                font-weight: $font-medium;
                margin: 0 1rem 0 1rem;
                line-height: 1.2;

                @include tablet() {
                    margin: 0 1rem 0 0;
                }
            }
        }
    }

    .product-tile-document-links {
        margin-top: 0.4rem;
        display: flex;
        flex-wrap: wrap;
        gap: 0.2rem;

        a {
            @include button(
                $background: rgba($textcolor, 0.25),
                $color: $textcolor,
                $hover-background: $lightgray,
                $hover-color: $textcolor,
                $font-size: $fs-xs,
                $min-height: 26px,
                $min-width: auto,
                $padding: .75rem 1.75rem
            );
            border: 1px solid transparent;
            font-weight: $font-medium;
            flex: 1 1 auto;
            column-gap: 1rem;

            &:hover  {
                border: 1px solid $lightgray;
            }
        }
    }
}
