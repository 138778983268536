// container
.skltbs-theme-dark {
    color: $color-dark-text;

    .skltbs {
        // tabs
        &-tab {
            background: $color-dark-fade;

            // focused tab
            &:focus {
                outline: 0;
                box-shadow: inset 0 0 0 $outline-width darken($color-dark-accent, 20);
            }

            // disabled tabs
            &:disabled {
                color: lighten($color-dark-text, 60);
                background: transparent;
            }

            // active tab
            &.skltbs-active {
                background: $color-dark-accent;

                &:focus,
                &:hover {
                    border-color: darken($color-dark-accent, 70);
                }
            }
        }

        // contents to toggle
        &-panel {
            background-color: $color-dark-background;
            border: 2px solid $color-dark-fade;

            &:focus {
                outline: 0;
                border-color: darken($color-dark-accent, 20);
            }
        }
    }
}
