.video-embed {
  .js-video-play {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4rem;
    height: 4rem;
    background: $green;
    border-radius: 100%;
    transform: translate(-50%,-50%) scale(1);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s ease;
    transform-origin: center;
    cursor: pointer;

    @include desktop {
      width: 6rem;
      height: 6rem;
    }

    &:hover:not(.hide) {
      transform: translate(-50%,-50%) scale(1.1);
    }

    &.hide {
      transform: translate(-50%, -50%) scale(0);
    }

    &:before {
      content: "";
      margin: 0 auto;
      position: relative;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: .75rem 0 .75rem 1.5rem;
      border-color: transparent transparent transparent #FFF;
      transform: translate(10%);

      @include desktop {
        border-width: 1.25rem 0 1.25rem 2.5rem;
      }
    }
  }
}
