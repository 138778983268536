// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.badge {
    display: inline-block;
    padding: .35em .65em;
    font-size: .75em;
    font-weight: $font-bold;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    background-color: $primary;

    // Empty badges collapse automatically
    &:empty {
        display: none;
    }
}

// Quick fix for badges in buttons
.button .badge,
.btn .badge {
    position: relative;
    top: -1px;
}
