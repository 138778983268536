.news-search-result {
  @extend .news-list-view;

  .page-layout-2 & {
    width: calc(100% + calc(#{$frame-padding} * 2 + 2rem));
    margin: 0 calc(-#{$frame-padding} - 1rem);
  }

  .count-msg {
    width: 100%;
    text-align: center;
  }
}
