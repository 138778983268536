.kiwigrid-numbers {
  text-align: center;

  [class*="col-"] {
    position: relative;

    @include mobile-max {
      flex-grow: 1;
    }
  }

  .row {

    &:first-child {
      margin: calc(5rem + 90px) 0 0;
    }

    @include desktop() {
      margin: calc(5rem + 90px) 0;
    }
  }

  .dot {
    @include dot($dynamic: false);
    top: -70px;
    left: 50%;

    @include tablet() {
      @include dot($dynamic: false);
      top: -90px;
      opacity: 0;
      transform: translate(-50%,-50%) scale(1);
      transition: all 0.5s ease;
    }
  }

  @include tablet() {
    [class*="col-"]:hover {
      @include hover {
        .dot {
          opacity: .8;
          transform: translate(-50%,-50%) scale(3);
        }
      }

      .kiwigrid-numbers__num {
        bottom: calc(100% - .5rem);
      }
    }
  }

  &__num {
    font-size: $fs-l;
    font-weight: bold;
    line-height: 1.25;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%);
    transition: all 0.5s ease;

    @include tablet() {
      font-size: $fs-xl;
    }
  }

  &__val {
    font-size: $fs-m;
    line-height: 1.25;
    padding: .75rem 1rem;
    position: relative;
    margin-bottom: 5rem;
  }

  &__val {
    &:before {
      content: "";
      top: 0;
      height: 1px;
      width: 100vw;
      position: absolute;
      border-bottom: 2px solid $white;
      left: calc(-50vw + 50%);
    }
  }
}
