.tx-kesearch-pi1 {
  position: relative;
  overflow: hidden;

  .js-search-open {
    cursor: pointer;
    background-color: $black;
    mask-image: url(/fileadmin/pictograms/css/Search.svg);
    -webkit-mask-image: url(/fileadmin/pictograms/css/Search.svg);
    mask-size: cover;
    -webkit-mask-size: cover;
    width: 30px;
    height: 30px;
    display: block;
  }

  form {
    position: absolute;
    width: auto;
    left: 30px;
    transform: translate(-100%);
    transition: all .8s ease;
  }

  &.open form {
    transform: translate(0);
  }

  .kesearchbox {
    margin: 0;
    padding: 0;
    height: 30px;
  }

  .resetbutt,
  .submitbutt {
    display: none;
  }

  #ke_search_sword {
    max-width: 100%;
    position: relative;
    border: 0;
    border-bottom: 1px solid;
    height: 35px;
    font-size: 12px;
    padding: .5em;

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }
  }
}
