/*! copyright @ xerc.de */

.frame-space {
  &-before {
    &-extra-small { margin-top: 1rem; }
    &-small       { margin-top: 2rem; }
    &-medium      { margin-top: 3rem; }
    &-large       { margin-top: 4rem; }
    &-extra-large { margin-top: 5rem; }
  }
  &-after {
    &-extra-small  { margin-bottom: 1rem; }
    &-small        { margin-bottom: 2rem; }
    &-medium       { margin-bottom: 3rem; }
    &-large        { margin-bottom: 4rem; }
    &-extra-large  { margin-bottom: 5rem; }
  }
}

@media only screen and (max-width: $screen-md) {
    .frame-line-dots4 {
        &.frame-space-after-extra-large {
            margin-bottom: 2rem;
        }

        .dot-frame {
            .frame-space-before-large {
                margin-top: 2rem;
            }
        }
    }
    .frame-layout-border {
        &.frame-space-after-extra-large, &.frame-space-after-large {
            margin-bottom: 2rem;
        }

        .frame-space-before-extra-large, .frame-space-before-large {
            margin-top: 2rem;
        }
    }
    .frame-line-dots2 {
        .frame-space-after-extra-large {
            margin-bottom: 1.5rem;
        }
        .frame-space-before-extra-large {
            margin-top: 1.5rem;
        }
    }
}
