.header-banner {
  top: 72px;
  position: absolute;
  width: 100%;
  z-index: 100;
  background: rgba($blueblack, 0.9);
  color: $white;

  @include desktop {
    top: calc(var(--header-height, 150px) - 1px);
  }

  .ce-textpic {
    display: flex;
    align-items: center;
  }

  .ce-intext.ce-left .ce-gallery {
    margin-bottom: 0;
    margin-top: 0;
    margin-bottom: .2rem;

    .image {
      display: flex;
      align-items: center;
    }
  }
}
