.frame {
    &.ce-eyecatcher {
        position: absolute;
        right: 0;
        top: 0;
        background: $green;
        width: auto;
        font-size: $fs-s;
        padding: 0.25rem $space-xs;

        a {
            text-decoration: none;
        }
    }
}