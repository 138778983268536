.compatible-device-list,
.compatible-device-list-selected {
    .accordion-header,
    .accordion-button,
    .accordion-body {
        font-size: 0.9rem;
        font-weight: $font-medium;
    }

    .accordion-button {
        line-height: 1.25rem;
        max-height: 72px;
        min-height: 72px;
    }

    .accordion-body {
        font-weight: $font-bold;
        padding-top: 0;

        .devices {
            border-top: 1px solid $gray;

            p:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .skltbs-tab {
        font-size: $fs-xs;
        font-weight: $font-medium;
    }

    .empty-category {
        border: 1px solid $gray;
        font-size: $fs-xs;
        font-weight: $font-medium;
        padding: 1rem;
        text-align: center;
        box-shadow: 0 1px 2px 0 rgba(46, 63, 80, 0.04);
    }
}

.compatible-device-list-selected {
    .accordion-header,
    .accordion-body {
        text-align: center;
    }

    .accordion-button {
        justify-content: center;
        text-align: center;

        &::after {
            display: none;
        }
    }
}
