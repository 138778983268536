
$nav-link-padding-x: 1rem;
$nav-toggler-width: 45px;
$nav-toggler-height: 35px;
$nav-toggler-bar-height: 3px;
$nav-toggler-padding-x: .5rem;
$nav-toggler-inner-padding-x: 5px;

.navbar-nav {

  .no-js & {
    display: block;
  }

  @include mobile-nav() {
    position: absolute;
    height: auto;
    background-color: $white;
    width: 100%;
    transition: opacity 0.5s ease, visibility 0s ease 0.5s;
    left: 0;
    top: 100%;
    opacity: 0;
    visibility: hidden;

    &.is-active {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.5s ease;
    }
  }
}

.mainnav {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;

  @include desktop-nav() {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__link {
    display: block;
    font-family: $headline-font;
    padding: .5rem 1rem;
    color: inherit;
    text-decoration: none;
    transition: none;

    @include desktop-nav() {
      padding: 2rem 1rem;
      font-weight: bold;
    }

    &[href^=http]:not([href^=https]) {
      @extend .external-link--http;
    }
  }

  &__link-inner {
    white-space: nowrap;

    @include desktop-nav() {
      position: relative;
      display: inline-block;

      &:after {
        content: "";
        display: block;
        border-top: 2px solid $green;
        width: 0;
        top: 100%;
        position: relative;
        transition: width 0.2s ease;
      }
    }
  }

  // sub pages
  &__link &__link-inner.current {
    position: absolute;
    min-width: 100%;
    white-space: nowrap;
    left: 50%;
    transform: translate(-50%);
    font-size: $fs-xs;
    transition: opacity 0.2s ease;

    &:before {
      top: -1px;
    }
  }

  &__item:hover &__link-inner.current {
    opacity: 0;
  }

  &__item {
    position: relative;

    @include desktop-nav() {
      .mainnav__link-inner.current,
      &.current > .mainnav__link > span > .mainnav__link-inner,
      &.current > .mainnav__link-inner,
      &:hover > .mainnav__link > span > .mainnav__link-inner,
      &:hover > .mainnav__link-inner {
        &:after {
          width: 100%;
        }
      }
    }

    &.current > .mainnav__link {
      font-weight: bold;
    }

    ul {
      display: inline-block;
      text-align: left;
      width: auto;
      transition: opacity 0.2s ease, visibility 0s ease .2s;
      color: $textcolor;

      @include desktop-nav() {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        min-width: 100%;
        width: auto;
        display: block;
        padding: 0;
        top: 100%;
        background: $white;
      }

      .mainnav__link {
        transition: color 0.2s ease;

        @include desktop-nav() {
          padding: 1rem;
        }

        @include mobile-max {
          font-size: 1rem;
          padding: .25rem 1rem;

          &:before {
            content: ">";
            margin-right: .5rem;
          }
        }
      }
    }

    &:hover ul {
      transition: opacity 0.2s ease .1s;

      @include desktop-nav() {
        position: absolute;
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.nav-toggler {
  display: flex;
  align-items: center;
  padding: .25rem $nav-toggler-padding-x;
  transition: all 0.2s ease;
  margin-left: 1rem;
  cursor: pointer;
  overflow: hidden;
  flex-shrink: 0;
  margin-right: calc(-#{$nav-toggler-padding-x} - #{$nav-toggler-inner-padding-x * 2});

  .no-js & {
    display: none;
  }

  @include desktop-nav() {
    display: none;
  }

  &__inner {
    display: block;
    padding: 5px $nav-toggler-inner-padding-x;
    float: right;
    width: $nav-toggler-width;
    height: $nav-toggler-height;
    border-radius: 100%;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
  }

  &__inner hr {
    display: block;
    position: absolute;
    height: $nav-toggler-bar-height;
    border-radius: 4px;
    width: $nav-toggler-width - 20px;
    background: $textcolor;
    opacity: 1;
    right: 10px;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    top: 0px;
    border: 0;
    margin: 0;
  }

  &__inner hr {
    top: 20%;
  }

  &__inner hr+hr {
    top: calc(50% - 2px);
  }

  &__inner hr+hr+hr {
    top: calc(80% - 4px);
  }

  &.is-active .nav-toggler__inner hr {
    border-radius: 2px;
  }

  &.is-active .nav-toggler__inner hr:first-child,
  &.is-active .nav-toggler__inner hr+hr+hr {
    width: 100%;
  }

  &.is-active .nav-toggler__inner hr:nth-child(1) {
    top: 50%;
    top: calc(50% - 2px);
    -webkit-transform: rotate(135deg) scaleX(0.65);
    -ms-transform: rotate(135deg) scaleX(0.65);
    transform: rotate(135deg) scaleX(0.65);
    right: 0;
  }

  &.is-active .nav-toggler__inner hr:nth-child(2) {
    opacity: 0;
  }

  &.is-active .nav-toggler__inner hr:nth-child(3) {
    top: 50%;
    top: calc(50% - 2px);
    -webkit-transform: rotate(-135deg) scaleX(0.65);
    -ms-transform: rotate(-135deg) scaleX(0.65);
    transform: rotate(-135deg) scaleX(0.65);
    right: 0;
  }
}

.nav-secondary {
  font-family: $headline-font;
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  margin-left: 1rem;
  font-weight: bold;

  .frame {
    margin: 0;
    padding: 0;
  }

  li {
    color: inherit;
    border-right: 1px solid currentColor;
    border-top: 2px solid transparent;

    &:last-child {
      border-right: 0;
    }

    span,
    a {
      padding: 0 .5rem;
      display: block;
    }

    span {
      opacity: 0.5;
    }

    a {
      color: inherit;
      text-decoration: none;
      transition: color .2s ease;

      &:hover {
        color: $green;
      }
    }
  }
}
