$accordion-color: inherit !default;
$accordion-bg: inherit !default;
$accordion-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease;
$accordion-border-color: $gray !default;
$accordion-border-width: 1px !default;
$accordion-btn-padding-x: 1.25rem !default;
$accordion-btn-padding-y: 1rem !default;
$accordion-btn-color: $textcolor !default;
$accordion-btn-bg: $white !default;
$accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23919aa3' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
$accordion-btn-icon-width: 1rem !default;
$accordion-btn-icon-transform: rotate(-180deg);
$accordion-btn-icon-transition: transform 0.2s ease-in-out;
$accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23919aa3' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
$accordion-body-padding-x: 1.25rem !default;
$accordion-body-padding-y: 1rem !default;
$accordion-active-color: $textcolor !default;
$accordion-active-bg: $white !default;

.accordion-item {
    background-color: $accordion-bg;
    border: $accordion-border-width solid $accordion-border-color;
    color: $accordion-color;

    &:not(:first-of-type) {
        border-top: 0;
    }
}

.accordion-button {
    align-items: center;
    background-color: $accordion-btn-bg;
    border: 0;
    border-radius: 0;
    color: $accordion-btn-color;
    display: flex;
    font-size: 1rem;
    overflow-anchor: none;
    padding: $accordion-btn-padding-y $accordion-btn-padding-x;
    position: relative;
    text-align: left;
    transition: $accordion-transition;
    width: 100%;

    &::after {
        flex-shrink: 0;
        width: $accordion-btn-icon-width;
        height: $accordion-btn-icon-width;
        margin-left: auto;
        padding-left: calc(#{$accordion-btn-padding-x} / 2);
        content: "";
        background-image: $accordion-btn-icon;
        background-position: right;
        background-repeat: no-repeat;
        background-size: $accordion-btn-icon-width;
        transition: $accordion-btn-icon-transition;
        transform: $accordion-btn-icon-transform;
    }

    &:not(.active)::after {
        background-image: $accordion-btn-active-icon;
        transform: none;
    }

    > img, > svg {
        margin-right: $accordion-btn-padding-x;
    }
}

.accordion-body {
    padding: $accordion-body-padding-y $accordion-body-padding-x;
}
