.news-single {
  overflow: visible;
  // padding-left: $frame-padding;
  // padding-right: $frame-padding;

  .news-text-wrap a {
    hyphens: auto;
  }

  .article {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: auto auto;

    @include desktop() {
      grid-template-columns: auto auto auto;
    }

    .footer {
      grid-row: 1;
      grid-column: 1;
    }

    .links {
      grid-row: 1;
      grid-column: 2;
    }

    .header {
      grid-row: 3;
      grid-column: 1 / span 2;
    }

    .news-img-wrap {
      grid-row: 4;
      grid-column: 1 / span 2;
    }

    .news-text-wrap {
      grid-row: 5;
      grid-column: 1 / span 2;

      > .frame {
        padding: 0;
      }
    }

    .news-related-wrap {
      grid-row: 6;
      grid-column: 1 / span 2;
    }
  }

  .footer {
    font-size: $fs-s;
    order: -1;

    .news-list-category,
    .news-list-tags,
    .news-list-date {
      display: block;
    }

    .news-list-category,
    .news-list-tags {
      font-weight: bold;
    }

    p {
      margin: 1.5rem 0;
    }
  }

  .news-img-caption {
    display: block;
    font-size: $fs-xs;
    padding-bottom: 1rem;
    margin: .5rem 0 0;
    border-bottom: 1px solid $gray;
  }

  .news-img-wrap {
    padding-bottom: 3rem;
  }

  .news-backlink-wrap,
  .social {
    text-align: right;
  }

  .links,
  .footer {
    border-bottom: 1px solid $gray;
  }

  .social {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: auto;

    a {
      padding: 0 .5em;
    }
  }

  .links {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 0;
  }

  .news-backlink-wrap {
    padding: 0 0 1em;

    a {
      text-decoration: none;
      font-size: 1rem;
      font-weight: $font-medium;

      &:before {
        content: "";
        border: solid $textcolor;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 2px;
        transform: rotate(135deg);
        top: -2px;
        position: relative;
      }
    }
  }

  .header {
    padding-top: 2rem;
    padding-bottom: 2rem;

    h1,h2,h3,h4,h5,h6 {
      font-weight: bold;
    }
  }

  /* related news */
  .news-related-wrap {
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid $gray;

    .news-related {

      h1,h2,h3,h4,h5 {
        font-size: $fs-s;
        font-weight: bold;
      }
    }

    .news-related-news-date {
      display: block;
      font-size: $fs-s;
    }

    a {
      margin: 1rem 0;
      display: block;
      font-size: $fs-m;
      line-height: 1.325;
      font-weight: bold;
      text-decoration: none;
    }

    .news-related__wrapper {
      --grid-gutter: 1rem;
      list-style-type: none;
      padding: 0;
      margin: 1.5rem 0;
    }

    .news-related__item {
      box-shadow: 0px 1px 6px lightgrey;
      padding: 2rem 1.5rem 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
