.ce-webinars {
    .ce-webinar {
        border-bottom: 2px solid $gray;
        padding-bottom: 1rem;
        padding-top: 2rem;

        a {
            color: $mint;
            text-decoration: none;
        }

        p {
            margin: 0;
        }
    }
}
