/*! copyright @ xerc.de */

#logo[onclick]:hover {
  cursor: pointer;
}

.mail-divider {
  &:before {
    content: "@";
  }
}

@media screen {
  // helper for responsive ratio of img/iframes
  // padding: 56.25%    - 16/9 (standard)
  // padding: 75%       - 4/3
  .ratio-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 0;

    .ratio-element {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }

    &--16x9 {
      padding-bottom: 56.25%;
    }

    &--4x3 {
      padding-bottom: 75%;
    }

    &--3x4 {
      padding-bottom: 133.33%;
    }

    &--3x2 {
      padding-bottom: 66.66%;
    }

    &--2x3 {
      padding-bottom: 150%;
    }

    &--1x1 {
      padding-bottom: 100%;
    }

    &--2x1 {
      padding-bottom: 50%;
    }

    &--3x1 {
      padding-bottom: 33.33%;
    }
  }
}

.hide-mobile {
  @include mobile-max() {
    display: none !important;
  }
}

.hide-tablet {
  @include tablet() {
    display: none !important;
  }
}

.hide-desktop {
  @include desktop() {
    display: none !important;
  }
}

.hidden {
  display: none !important;
}

.visually-hidden {
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important; // Fix for https://github.com/twbs/bootstrap/issues/25686
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;

    &:not(caption) {
        position: absolute !important;
    }
}

.blocked {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.external-link {
  &:after {
    content: "";
    background-color: currentColor;
    mask-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='M9.56.08h10.19c.09 0 .15.07.15.15v10.19c0 .09-.07.15-.15.15H18.7a.15.15 0 01-.15-.15V2.3L9.5 11.67a.16.16 0 01-.22 0l-.73-.71a.16.16 0 010-.22l9-9.32H9.57a.15.15 0 01-.15-.15V.23c-.01-.08.06-.15.14-.15z'/><path d='M8.44 3.07H.25a.15.15 0 00-.15.15V19.8c0 .08.07.15.15.15H16.6c.08 0 .15-.07.15-.15v-8.95a.15.15 0 00-.15-.15h-.96a.15.15 0 00-.15.15v7.81H1.37V4.31l7.07.01c.08 0 .15-.07.15-.15v-.96c0-.07-.07-.14-.15-.14z'/></svg>");
    -webkit-mask-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path d='M9.56.08h10.19c.09 0 .15.07.15.15v10.19c0 .09-.07.15-.15.15H18.7a.15.15 0 01-.15-.15V2.3L9.5 11.67a.16.16 0 01-.22 0l-.73-.71a.16.16 0 010-.22l9-9.32H9.57a.15.15 0 01-.15-.15V.23c-.01-.08.06-.15.14-.15z'/><path d='M8.44 3.07H.25a.15.15 0 00-.15.15V19.8c0 .08.07.15.15.15H16.6c.08 0 .15-.07.15-.15v-8.95a.15.15 0 00-.15-.15h-.96a.15.15 0 00-.15.15v7.81H1.37V4.31l7.07.01c.08 0 .15-.07.15-.15v-.96c0-.07-.07-.14-.15-.14z'/></svg>");
    mask-size: cover;
    -webkit-mask-size: cover;
    width: 0.8em;
    height: 0.8em;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -0.1em;
    left: 0.4em;
    margin-right: 0.75em;
    flex-shrink: 0;
  }

  &:hover:after {
    opacity: .8;
  }
}

.external-link--http {
  @extend .external-link;
  &:after {
    background-color: red;
  }
}

p a {
  &[href^=https] {
    @extend .external-link;
  }
  &[href^=http]:not([href^=https]) {
    @extend .external-link--http;
  }
}
