//fixes missing viewbox & width/height on svgs
#page-4283 main > .row-fluid:first-child .ce-gallery svg {
  max-height: 100px;
}

#page-4283 main .frame-scrollsection .col-6-lg + .col-6-lg .ce-gallery {
  @include mobile-max {
    svg {
      max-width: 100px;
    }
    img {
      max-width: 200px;
    }
  }
}

#page-4863 main > .row-fluid:first-child[data-img-background] object,
#page-4863 main > .row-fluid:first-child[data-img-background] svg {
  width: MIN(95vw,1200px);
  left: 50%;
  transform: translate(-50%);
  margin-bottom: -2.5rem;

  @include mobile-max {
    margin-bottom: -1rem;
  }
}

#page-4503 main > .row-fluid:nth-child(2)[data-img-background] object,
#page-4503 main > .row-fluid:nth-child(2)[data-img-background] svg {
  width: MIN(95vw,1200px);
  left: 50%;
  transform: translate(-50%);
  margin-bottom: -2.5rem;

  @include mobile-max {
    margin-bottom: -1rem;
  }
}
