.ce-gallery {

  // team (ce-image)
  .frame-type-image > .ce-image > &.ce-border img,
  // tih products icons
  .frame-type-textpic > .ce-textpic.ce-above > &.ce-border img,
  .frame-type-textmedia > .ce-textpic.ce-above > &.ce-border .image {
    border-radius: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // tih products icons
  .ce-textpic.ce-above &.ce-border {
    .image {
      background: $lightgray;
    }
    img,
    svg {
      width: 50px;
      height: 50px;
    }
  }

  .ce-center > & .ce-column {
    text-align: center;
  }

  .ce-below > & .ce-row {
    display: flex;
    align-items: flex-end;
    gap: 0.25rem;
  }

  .ce-column {

    &.animated img {
      transform: translate(0, 20%);
      opacity: 0;
      transition-property: none;
    }

    &.animated.active img {
      opacity: 1;
      transform: translate(0, 0);
      transition-property: all;
      transition-duration: 1s;
      transition-timing-function: ease;
    }

    @for $i from 1 through 20 {
      &:nth-child(#{$i}n) img {
        transition-delay: #{$i * 0.1}s;
      }
    }
  }

  .image-caption {
    font-size: 80%;
    text-align: left;
  }

  a {
    display: block;
    transition: transform .2s ease;

    &:hover {
      transform: scale(1.05);
    }
  }
}
