@mixin dot($dynamic: true) {
  transform: translate(-50%,-50%);
  position: absolute;
  width: 31px;
  height: 31px;
  background-color: $gray;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  transition: background-color 0.2s ease;

  &:before {
    content: "";
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    background-color: rgba($white, 0.7);
    display: block;
    position: absolute;
    border-radius: 100%;
  }

  &:after {
    content: "";
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    background-color: $textcolor;
    display: block;
    position: absolute;
    border-radius: 100%;
    transition: background-color 0.2s ease;
  }

  @if ($dynamic) {
    &.is-active {
      background-color: rgba($green, 0.5);
      animation-name: background-green-pulse;
      animation-duration: 3s;
      animation-iteration-count: infinite;

      &:after {
        background-color: $green;
      }
    }
  }
  @else {
    background-color: rgba($green, 0.5);
    animation-name: background-green-pulse;
    animation-duration: 3s;
    animation-iteration-count: infinite;

    &:after {
      background-color: $green;
    }
  }
}

@keyframes background-green-pulse {
  from { background-color: rgba($green, 0.8) }
  50% { background-color: rgba($green, 0.1) }
  to { background-color: rgba($green, 0.8) }
}
