$default-font-path: '../Fonts';

@font-face {
  font-family:'Anago';
  font-weight:800;
  font-style:normal;
  src:local('Anago Black'),local('Anago-Black'),
      url('#{$default-font-path}/Anago-Black-subset.woff2') format('woff2'),
      url('#{$default-font-path}/Anago-Black-subset.woff')  format('woff');
  font-display: block;
}
@font-face {
  font-family:'Anago';
  font-weight:700;
  font-style:normal;
  src:local('Anago Bold'),local('Anago-Bold'),
      url('#{$default-font-path}/Anago-Bold-subset.woff2') format('woff2'),
      url('#{$default-font-path}/Anago-Bold-subset.woff')  format('woff');
  font-display: block;
}
@font-face {
  font-family:'Anago';
  font-weight:500;
  font-style:normal;
  src:local('Anago Medium'),local('Anago-Medium'),
      url('#{$default-font-path}/Anago-Medium-subset.woff2') format('woff2'),
      url('#{$default-font-path}/Anago-Medium-subset.woff')  format('woff');
  font-display: block;
}
@font-face {
  font-family:'Anago';
  font-weight:400;
  font-style:normal;
  src:local('Anago Book'),local('Anago-Book'),
      url('#{$default-font-path}/Anago-Book-subset.woff2') format('woff2'),
      url('#{$default-font-path}/Anago-Book-subset.woff')  format('woff');
  font-display: block;
}
