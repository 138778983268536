@mixin bullet() {
  background-color: $textcolor;
  mask-image: url(/fileadmin/pictograms/css/Checked.svg);
  -webkit-mask-image: url(/fileadmin/pictograms/css/Checked.svg);
  mask-size: cover;
  -webkit-mask-size: cover;

  .frame-layout-gray-cite &,
  .frame-layout-gray & {
    background-color: $mint;
  }

  [data-img-background] &,
  .frame-layout-blue-cite &,
  .frame-layout-blue &,
  .frame-layout-bluetrans & {
    background-color: $white;
  }
}
