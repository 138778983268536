.news {
  width: 100%;
  overflow: hidden;
}

.news-list-view {

  // news list on normal page
  .page-layout-0 &,
  .page-layout-1 & {
    display: flex;
    flex-wrap: wrap;
    color: $white;

    .article {
      flex-basis: 100%;
      width: 100%;
      height: 100vw;
      flex-grow: 0;
      flex-shrink: 0;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      @include tablet() {
        flex-basis: 50%;
        width: 50%;
        height: 50vw;
      }

      @include desktop() {
        flex-basis: 33.3333%;
        width: 33.3333%;
        height: 33vw;
      }

      a {
        color: $white;
        text-decoration: none;
      }

      .header,
      .footer {
        background: rgba($blueblack, 0.9);
        padding: 0 $frame-padding;
        text-align: center;
      }

      .header,
      .footer,
      .teaser-text {
        z-index: 1;
      }

      .header {
        padding-bottom: 1.75rem;

        h1,h2,h3,h4,h5,h6 {
          max-width: 70%;
          margin: auto;
        }
      }

      .footer {
        order: -1;
        font-size: $fs-s;
        padding-top: .75rem;

        p {
          margin-bottom: 0;
        }

        .news-list-tags,
        .news-list-category {
          display: none;
        }
      }

      .teaser-text,
      .news-img-wrap {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }

      .teaser-text {
        background-color: rgba($mint, 0.8);
        opacity: 0;
        transition: all 0.2s ease;
        z-index: 2;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 2rem;
        padding-bottom: 5rem;

        > div {
          overflow: hidden;
        }

        > .more {
          font-weight: bold;
          position: absolute;
          top: 0;
          bottom: 0;
          padding: 2rem;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: center;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: $fs-m;
          margin: 0 0 1rem 0;
        }
      }

      &:hover {
        .teaser-text {
          opacity: 1;
        }
        .footer,
        .header {
          opacity: 0;
        }
      }

      .header h1,
      .header h2,
      .header h3,
      .header h4 {
        font-size: $fs-m;
        margin-top: 0;
        font-weight: bold;
      }

      .header h1,
      .header h2,
      .header h3,
      .header h4,
      .footer p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .news-img-wrap {

        a {
          height: 100%;
          width: 100%;
          display: block;
        }

        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  .f3-widget-paginator {
    padding: 0 $frame-padding;
    display: flex;
    float: none;
    list-style: none;
    width: 100%;
    align-items: center;
    justify-content: center;

    a {
      color: $textcolor;
      padding: .5rem;
      text-decoration: none;
      transition: color .2s ease;

      &:hover {
        color: $green;
      }
    }

    > p {
      display: none;
    }

    li::marker {
      font-size: 0;
    }

    li {
      width: 2rem;
      text-align: center;
      position: relative;
    }

    .current {
      font-weight: bold;
    }
  }
}
