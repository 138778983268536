[data-img-background] {

  // first frame svg background
  main > &[data-svg-background] {
    padding-bottom: MIN(10vw, 300px);
  }

  // white text on image backgrounds (non-svg)
  &:not([data-svg-background]) {
    color: $white;
  }

  > picture,
  > div {

    &.animated img {
      transform: translate(0, 20%);
      opacity: 0;
      transition-property: none;
    }

    &.animated.active img {
      opacity: 1;
      transform: translate(0, 0);
      transition-property: all;
      transition-duration: 1s;
      transition-timing-function: ease;
    }

    &.contain > svg,
    &.contain > object {
      top: auto;
      margin-bottom: -2rem;
    }
  }

  // map -> grid element/frame class ?
  main > &[data-svg-background]:last-child {
    padding-bottom: 54vw;

    > div.contain > svg,
    > div.contain > object {
      margin-bottom: 0;
      object-fit: cover;
      height: 100%;
    }
  }
}
