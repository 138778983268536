.nav-header {
    .link-logo {
        display: none;

        @include desktop-nav() {
            max-width: 135px;
            margin-top: 0.6rem;
            margin-left: 10vw;
            display: block;
        }

        svg {
            height: 40px;
        }
    }
}
