/* Text */
.text-left, .ce-align-left { text-align: left; }
.text-center, .ce-align-center { text-align: center; }
.text-right, .ce-align-right { text-align: right; }
.text-justify, .ce-align-justify { text-align: justify; }

/* Headline */
.ce-headline-left { text-align: left; }
.ce-headline-center { text-align: center; }
.ce-headline-right { text-align: right; }

/* Text Image */
.ce-textpic, .ce-image, .ce-nowrap .ce-bodytext, .ce-gallery, .ce-row, .ce-uploads li, .ce-uploads div { overflow: hidden; }

.ce-left .ce-gallery, .ce-column { float: left; }
.ce-center .ce-outer { position: relative; float: right; right: 50%; }
.ce-center .ce-inner { position: relative; float: right; right: -50%; }
.ce-right .ce-gallery { float: right; }

.ce-intext.ce-right .ce-gallery, .ce-intext.ce-left .ce-gallery, .ce-above .ce-gallery {
  margin-bottom: 1rem;
}
.ce-intext.ce-right .ce-gallery { margin-left: 1rem; }
.ce-intext.ce-left .ce-gallery { margin-right: 1rem; }

.ce-above .ce-bodytext { clear: both; }
