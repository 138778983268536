.slick-slider {
  width: 100%;
}

.slick-dots {
  margin: 1rem auto;
  display: flex;
  list-style: none;
  padding: 0;

  li {
    padding: .325rem;
    cursor: pointer;

    &:before {
      content: "";
      display: block;
      background: none;
      border: 0;
      font-size: 0;
      background: $lightgray;
      height: 0.75rem;
      width: 0.75rem;
      border-radius: 100%;
      display: block;
      transition: all 0.2s ease;
    }

    &.slick-active {
      &:before {
        background: $mint;
        transform: scale(1.5);
      }
    }

    button {
      display: none;
    }
  }
}

.slick-slide {
  > div > .row {
    display: block !important;

    > [class^="col-"] {
      display: inline-block;
    }
  }
}
