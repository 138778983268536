$checkedIcon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check-circle</title><path d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" /></svg>');
$checkedGreenIcon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check-circle</title><path d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" fill="%236fbc85" /></svg>');
$minusOutlineIcon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>minus-circle-outline</title><path d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M7,13H17V11H7" fill="%232e405080" /></svg>');
$minusFilledIcon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>minus-circle</title><path d="M17,13H7V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" fill="%232e405080" /></svg>');
$closeIcon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>close-circle-outline</title><path d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2C6.47,2 2,6.47 2,12C2,17.53 6.47,22 12,22C17.53,22 22,17.53 22,12C22,6.47 17.53,2 12,2M14.59,8L12,10.59L9.41,8L8,9.41L10.59,12L8,14.59L9.41,16L12,13.41L14.59,16L16,14.59L13.41,12L16,9.41L14.59,8Z" fill="%232e405080" /></svg>');
$partlyIcon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>checkbox-marked-circle-minus-outline</title><path d="M14.3 21.7C13.6 21.9 12.8 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C13.3 2 14.6 2.3 15.8 2.7L14.2 4.3C13.5 4.1 12.8 4 12 4C7.6 4 4 7.6 4 12C4 16.4 7.6 20 12 20C12.4 20 12.9 20 13.3 19.9C13.5 20.6 13.9 21.2 14.3 21.7M7.9 10.1L6.5 11.5L11 16L21 6L19.6 4.6L11 13.2L7.9 10.1M15 17V19H23V17H15Z" fill="%232e405080" /></svg>');

.capability-matrix {
    margin: 0 0.25rem 1.5rem 0.25rem;

    @media only screen and (min-width: $screen-sm) {
        margin: 0 1rem 1.5rem 1rem;
    }

    .capability-legend {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 1rem;
        padding: 2rem 4rem;

        // keep the legend-label in own row
        .legend-label {
            flex-basis: 100%;
        }

        @media only screen and (min-width: $screen-sm) {
            gap: 1.5rem;
            justify-content: center;

            .legend-label {
                flex-basis: auto;
            }
        }
    }

    i.capability-icon {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        display: inline-block;
        height: 1.5rem;
        vertical-align: middle;
        width: 1.5rem;

        &.supported {
            background-image: $checkedGreenIcon;
        }
        &.limited {
            //background-image: $partlyIcon;
            background-image: $minusOutlineIcon;
        }
        &.not-supported {
            background-image: $closeIcon;
        }
        &.not-available {
            background-image: $minusFilledIcon;
        }
    }

    .matrix-datatable {
        font-size: $fs-xs;
        hyphens: auto;

        thead {
            th {
                min-width: 120px;

                @media only screen and (max-width: $screen-sm) {
                    padding: 5px;

                    &.dt-orderable-asc, &.dt-orderable-desc {
                        padding-right: 20px;


                        .dt-column-order {
                            right: 5px;
                        }
                    }

                }

                &:first-child {
                    border-right: 1px solid rgba(0, 0, 0, 0.3);
                    hyphens: none;
                    white-space: nowrap;

                    @media only screen and (min-width: $screen-sm) {
                        width: auto;
                    }
                }
            }

        }
        tbody {
            td {
                // reduce spacing between multiline text
                line-height: 1.25rem;

                @media only screen and (max-width: $screen-sm) {
                    padding: 5px;
                }

                &:first-child {
                    border-right: 1px solid rgba(0, 0, 0, 0.3);
                }

                .icon-table-status {
                    padding-left: 10px;
                }
            }
        }
    }

    .capability-download-container {
        margin-bottom: 3rem;

        .button--download {
            margin-top: 0.75rem;
        }
    }

    .dt-container {
        > .dt-layout-row:first-child {
            margin-top: 0;
        }

        .dt-paging {
            .dt-paging-button {
                &.first, &.last {
                    display: none;
                }
            }
        }
    }
}
