.news-list-view {

  // news page (Stories)
  .page-layout-2 & {
    display: flex;
    flex-wrap: wrap;
    padding: 0 $frame-padding;
    width: calc(100% + 2rem);
    margin: 0 -1rem;

    a {
      text-decoration: none;

      &.more {
        font-weight: bold;
        color: $mint;
      }
    }

    .article {
      flex-basis: 100%;
      width: 100%;
      flex-grow: 0;
      flex-shrink: 0;
      padding: 1rem;

      display: flex;
      flex-direction: column;

      @include desktop() {
        flex-basis: 50%;
        width: 50%;

        &.topnews {
          flex-basis: 100%;
          width: 100%;
        }
      }
    }

    .news-img-wrap {
      order: -2;
    }

    .footer {
      order: -1;
    }

    .header {
      h1,h2,h3,h4,h5,h6 {
        font-weight: bold;
      }
    }

    .footer {
      font-size: $fs-xs;

      p {
        margin: 0;
      }
    }

    .news-img-wrap,
    .header,
    .teaser-text .more {
      margin: .5rem 0 1rem;
      display: block;
    }

    .teaser-text {
      > * {
        display: none;
      }
      > .more {
        display: block;
      }
    }

    .news-list-topnews {
      background-color: $lightgray;
      padding: 0.4em .5em;
      opacity: 0.9;
      white-space: nowrap;
    }
  }
}
