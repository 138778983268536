body {
  color: $textcolor;
}

h1,h2,h3,h4,h5,h6 {
  font-weight: $font-normal;
  line-height: 1.25;
  margin: 0;
}

h1 {
  @include mobile-max {
    font-size: $fs-xl;
  }
}
h2 {
  @include mobile-max {
    font-size: $fs-l;
  }
}
h3 {
  @include mobile-max {
    font-size: $fs-m;
  }
}
h4 {
  @include mobile-max {
    font-size: $fs-sm;
  }
}
h5 {
  @include mobile-max {
    font-size: $fs-s;
  }
}
h6 {
  @include mobile-max {
    font-size: $fs-xs;
  }
}

b {
  font-weight: $font-medium;
}

a {
  color: inherit;
  text-decoration: underline;
}

main {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;

  .page-layout-0 & > [class*='frame-']:first-child,
  .page-layout-2 & > [class*='frame-']:first-child {
    padding-top: var(--header-height, 72px);

    @include desktop {
      padding-top: var(--header-height, 150px);
    }
  }

  // teaser image
  .page-layout-1 & > [class*='frame-full-height']:first-child,
  .page-layout-1 & > [class*='frame-']:not(.frame-full-height):first-child > .frame-full-height:first-child {
    padding-top: var(--header-height, 72px);

    @include desktop {
      padding-top: var(--header-height, 150px);
    }

    > * {
      z-index: 2;
    }

    > picture {
      z-index: 0;
    }

    // gradient
    &:not([data-svg-background]):before {
      content: "";
      background: linear-gradient(90deg,rgba(0,0,0,.5) 0%,rgba(0,0,0,.3) 100%);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;

      @include tablet() {
        background: linear-gradient(90deg,rgba(0,0,0,.5) 0%,rgba(0,0,0,0) 50%);
      }
    }

  }
}

.btn {
  @extend .button;
}

.ce-bodytext {

    h1,h2,h3,h4,h5,h6 {
        margin: 0;
    }

    a {
        color: $mint;
        text-decoration: none;
    }

    a.button--block, a.button {
        color: $white;
    }
}

video {
  max-width: 100%;
}
