//
// Variables
// --------------------------------------------------
$cardpanel-header-background:       transparent !default;
$cardpanel-header-color:            inherit !default;
$cardpanel-border-color:            inherit !default;
$cardpanel-variants: () !default;
$cardpanel-variants: map-merge(
    (
        'default': (
            'header-background':    $default,
            'header-color':         color-contrast($default),
            'border-color':         darken($default, 15%),
        ),
        'primary': (
            'header-background':    $primary,
            'header-color':         color-contrast($primary),
            'border-color':         darken($primary, 15%),
        ),
        'secondary': (
            'header-background':    $secondary,
            'header-color':         color-contrast($secondary),
            'border-color':         darken($secondary, 15%),
        ),
    ),
    $cardpanel-variants
);

//
// CSS Variables
// --------------------------------------------------
:root {
    --cardpanel-header-background: #{$cardpanel-header-background};
    --cardpanel-header-color: #{$cardpanel-header-color};
    --cardpanel-border-color: #{$cardpanel-border-color};
}

//
// Card Panel
// --------------------------------------------------
.card-panel {
    border-color: var(--cardpanel-border-color);
    border-style: solid;
    border-width: 1px;
    word-wrap: break-word;

    .card-header {
        background: var(--cardpanel-header-background);
        color: var(--cardpanel-header-color);
        font-size: 1rem !important;
        padding: 1rem 1rem;
    }
    .card-body {
        padding: 2rem;

        @include tablet() {
            padding: 2rem 5rem;
        }

        @include large() {
            padding: 2rem 10rem;
        }
    }
}
@each $name, $settings in $cardpanel-variants {
    $tmp-header-background: map-get($settings, 'header-background');
    $tmp-header-color: map-get($settings, 'header-color');
    $tmp-border-color: map-get($settings, 'border-color');
    .card-panel-#{$name} {
        @if ($tmp-header-background) {
            --cardpanel-header-background: #{$tmp-header-background};
        }
        @if ($tmp-header-color) {
            --cardpanel-header-color: #{$tmp-header-color};
        }
        @if ($tmp-border-color) {
            --cardpanel-border-color: #{$tmp-border-color};
        }
    }
}
