/*! copyright @ xerc.de */

[data-img-background] {
  position: relative;

  > picture,
  > div {

    > object,
    > svg,
    > img {
      object-fit: cover;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      margin: 0 auto;
    }

    &.contain > object,
    &.contain > svg,
    &.contain > img {
      object-fit: contain;
    }

    &.contain > object,
    &.contain > svg {
      height: auto;
      max-height: 100%;
    }
  }
}