
.button--primary-small {
    @include button(
        $background: $primary,
        $color: $white,
        $hover-background: lighten($primary, 10%),
        $hover-color: $white,
        $min-width: auto,
        $min-height: 40px,
        $font-size: $fs-xs,
    );
}

.button--secondary {
    @include button(
        $background: transparent,
        $color: $primary,
        $hover-background: $primary,
        $hover-color: $white,
        $font-size: $fs-sm,
        $min-height: 56px,
    );
    border: 1px solid $primary;
    font-weight: bold;
}

.button--secondary-small {
    @extend .button--secondary;

    font-size: $fs-xs;
    min-width: auto;
    min-height: 40px;
}

.button--muted {
    @include button(
        $background: transparent,
        $color: rgba($textcolor, 0.5),
        $hover-background: $lightgray,
        $hover-color: $textcolor,
        $font-size: $fs-xs,
        $min-height: 40px,
        $min-width: auto,
    );
    border: 1px solid rgba($textcolor, 0.5);
    font-weight: $font-medium;
    margin: 0 0.1rem 0.4rem;

    &:hover {
        border: 1px solid $lightgray;
    }

    &.active {
        border: 1px solid $lightgray;
        background-color: $lightgray;
        color: $textcolor;
    }
}

.button--arrow-right {
    @include button(
        $background: transparent,
        $color: $textcolor,
        $hover-background: transparent,
        $hover-color: $textcolor,
        $font-size: $fs-xs,
        $min-height: 40px,
        $min-width: auto,
        $padding: 0.5rem 1rem,
    );
    border: 1px solid $textcolor;
    font-weight: $font-medium;
    line-height: 20px;
    margin: 0 0.1rem 0.4rem;

    &:after {
        @include arrow-right-icon($textcolor);
        content: "";
        color: $textcolor;
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-left: 0.5rem;
    }

    &:hover, &.active {
        border: 1px solid $primary;
        color: $primary;

        &:after {
            @include arrow-right-icon($primary);
            color: $primary;
        }
    }
}

.button--download {
    &:after {
        @include download-icon($white);
        content: "";
        color: $white;
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-left: 0.5rem;
    }
}

.button--outline {
    @include button(
        $background: transparent,
        $color: $primary,
        $hover-background: $primary,
        $hover-color: $white,
        $font-size: $fs-sm,
        $min-height: 56px,
    );
    border: 1px solid $primary;

    &:hover {
        border: 1px solid $primary;
    }
}

.button--outline-gray {
    @include button(
        $background: transparent,
        $color: $textcolor,
        $hover-background: transparent,
        $hover-color: $textcolor,
        $font-size: $fs-sm,
        $min-height: 56px,
    );
    border: 1px solid $textcolor;

    &:hover {
        border: 1px solid $primary;
        color: $primary;
    }
}

.button--outline-gray-small {
    @extend .button--outline-gray;

    font-size: $fs-xs;
    min-width: auto;
    min-height: 40px;
}

.btn--light {
    @include button(
        $background: $white,
        $color: rgba($textcolor, 0.5),
        $hover-background: $darkgray,
        $hover-color: $white,
        $font-size: $fs-xs,
        $min-height: 40px,
        $min-width: auto,
        $padding: 0.5rem 0.75rem,
    );
    border: 1px solid rgba($textcolor, 0.5);
    border-radius: 4px;
    margin: 0.2rem 0.375rem;

    &:hover  {
        border: 1px solid $darkgray;
    }

    &.btn-sm {
        min-height: 34px;
    }

    &.remove-facet-option {
        padding-right: 2.5rem;
        position: relative;

        &:after {
            content:"";
            position: absolute;
            height: 100%;
            width: 1rem;
            right: 0.75rem;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23808D97'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3C/svg%3E");
        }

        &:hover {
            &:after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23fff'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3C/svg%3E");
            }
        }
    }
}


.btn--facet {
    @include button(
        $background: rgba($gray, 0.5),
        $color: $textcolor,
        $hover-background: $white,
        $hover-color: rgba($textcolor, 0.5),
        $font-size: $fs-xs,
        $min-height: 40px,
        $min-width: auto,
        $padding: 0.35rem 0.65rem,
    );
    border: 1px solid transparent;
    border-radius: 4px;
    margin: 0.2rem 0.375rem 0.2rem 0;

    &:hover  {
        border: 1px solid rgba($textcolor, 0.5);
    }

    &.btn-sm {
        min-height: 34px;
    }

    &.remove-facet-option {
        @include button(
            $background: $white,
            $color: rgba($textcolor, 0.5),
            $hover-background: $darkgray,
            $hover-color: $white,
            $font-size: $fs-xs,
            $min-width: auto,
            $min-height: 34px,
            $padding: 0.35rem 0.65rem,
        );
        border: 1px solid rgba($textcolor, 0.5);
        padding-right: 2.5rem;
        position: relative;

            .facet-result-count {
                display: none;
            }

            &:after {
            content:"";
            position: absolute;
            height: 100%;
            width: 1rem;
            right: 0.75rem;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23808D97'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3C/svg%3E");
        }
    }

    .facet-result-count {
        font-weight: 600;
        padding-left: 0.25rem;
    }
}


//
// Button Sizes
//

.button--large,
.btn-lg {
    font-size: $fs-l;
    min-height: 56px;
}

.button--small,
.btn-sm {
    font-size: $fs-xs;
    min-height: 34px;
}
