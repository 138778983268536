.frame-type {
  &-bullets {

    + .frame-type-bullets {
      margin-top: -1em;
    }

    .ce-bullets {
      list-style: none;
      position: relative;
      padding-left: 2.5rem;

      li {
        display: block;
        min-height: 2.5rem;
        overflow: hidden;

        &:before {
          content: "";
          width: 1.5rem;
          height: 1.5rem;
          left: 0;
          position: absolute;
          @include bullet();
        }
      }

      &.unchecked {
        li {
           &:before {
             mask-image: url(/fileadmin/pictograms/css/Unchecked.svg);
             -webkit-mask-image: url(/fileadmin/pictograms/css/Unchecked.svg);
          }
        }
      }
    }
  }
}
