@mixin klapper($opener: true, $color: $black) {
  display: block;
  width: 100%;

  > *:not(header) {
    height: 0;
    overflow: hidden;
  }

  > header {
    position: relative;
    cursor: pointer;
    margin: 0;
    transition: color 0.3s ease;
  }

  @if $opener {
    @include opener-plus($right: 50%, $top: 100%, $size: 1rem, $color: $color, $openClass: '.is-open');
  }
}

@mixin opener-plus($right: auto, $top: auto, $left: auto, $bottom: auto, $size: 20px, $color: $black, $openClass: '.is-open') {
  padding-bottom: $size;

  &::after {
    cursor: pointer;
    content: " ";
    display: block;
    background: linear-gradient($color,$color), linear-gradient($color,$color), transparent;
    background-position: center;
    background-size: 50% 1px,1px 50%;
    background-repeat: no-repeat;
    border: 1px solid $color;
    border-radius: 100%;
    width: calc(#{$size} + 2px);
    height: calc(#{$size} + 2px);
    margin: auto;
    margin-top: $size;
  }

  &#{$openClass} {
    &::after {
      background: linear-gradient($color,$color), linear-gradient(transparent,transparent), transparent;
      background-position: center;
      background-size: 50% 1px,1px 50%;
      background-repeat: no-repeat;
    }
  }
}

