.nav-dots {
  position: fixed;
  right: 0;
  top: 48vh;
  transform: translate(-12px, -50%);
  z-index: 2;

  @include large {
    top: 39vh;
  }

  &__dot {
    display: block;
    padding: 5px;

    &:before {
      content: "";
      width: 10px;
      height: 10px;
      display: block;
      background: $gray;
      border-radius: 100%;
      transition: all .1s ease-in-out;
    }

    &:hover::before {
      transform: scale(1.4);
      transform-origin:5px 5px;
    }
    &.is-active:before {
      background: $mint;
    }
  }
}
