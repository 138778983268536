.frame-type-news_categorylist > .news {
  overflow: visible;

  > ul {
    visibility: hidden;

    .no-js &,
    &.list-select {
      visibility: hidden;
    }
  }
}
.list-select-wrapper {
  position: relative;
  height: 50px;
  z-index: 10;
}

.list-select {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-block;
  position: absolute;
  top: 0;

  li {
    background-color: $lightgray;
    z-index: 1;
    visibility: hidden;
    overflow: hidden;
    opacity: 0;
    font-size: 0;
    height: 0;
    border-bottom: 1px solid $white;
    transition: all 0s ease 2s, opacity 0.4s ease;

    a {
      padding: .325rem .75rem;
      display: block;
      font-size: 1rem;
      text-decoration: none;

      &.active {
        font-weight: bold;
      }
    }

    &.selected,
    &.visible {
      visibility: visible;
      height: auto;
      opacity: 1;
      transition: all 0s ease, opacity 0.4s ease .1s;
    }

    &:hover {
      background-color: $gray;
    }
  }
}
