@include effect('rotate') {
    .skltbs-panel-group {
        overflow: hidden;
    }

    .skltbs-panel {
        position: relative;
        transition: $duration;
        transition-property: opacity, transform;
    }

    .skltbs-leave {
        position: absolute;
        top: 0;
        @include force-visible;
        opacity: 1;
        transform: translate3d(0, 0, 0);

        &-active {
            opacity: 0;
            transform: translate3d(-50%, 0, 0);
        }
    }

    .skltbs-enter {
        opacity: 0;
        transform: translate3d(50%, 0, 0);

        &-active {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
}
