.anim-laser {
  stroke-dasharray: 100;
  animation: laser 4s linear;
}
@keyframes laser {
  to {
    stroke-dashoffset: 1000;
  }
}

.anim-blink {
  opacity: 0;
  animation: blink 5s linear alternate infinite;
}
@keyframes blink {
  0% {
    opacity: 1;
    filter:none;
  }
  100% {
    opacity: 1;
    filter:
      drop-shadow(-4px -4px  3px #c7cc0c)
      drop-shadow(-4px  5px  3px #c7cc0c)
      drop-shadow( 5px -4px  3px #c7cc0c)
      drop-shadow( 5px  5px  3px #c7cc0c)
    ;
  }
}

.anim-illume {
  animation: onOff 4s linear infinite;
}
@keyframes onOff {
  0% {
    fill: $white;
  }
}

.anim-moveY {
  animation: moveY 2s linear infinite;
}
@keyframes moveY {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(100px);
  }
}

.anim-shrinkY {
  stroke-dasharray: 100;
  animation: shrinkY 10s linear alternate infinite;
}
@keyframes shrinkY {
  from {
    stroke-dashoffset: 100;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.anim-rays {
  animation: brth 5s ease-in-out infinite;
}
@keyframes brth {
  0%,100% {
    filter:none;
  }
  20% {
    filter:drop-shadow(0 0  3px rgba(197,202,8,1));
  }
  50% {
    filter:drop-shadow(0 0 10px rgba(197,202,8,1));
    stroke:rgba(197,202,8,1);
  }
  80% {
    filter:drop-shadow(0 0  3px rgba(197,202,8,1));
  }
}

.anim-spin {
  animation: spin 2s linear infinite;
  transform-origin: 354px 209px;
}
@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.anim-fadeIn {
  animation: fadeIn 4s ease-in;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
