@include effect('drop') {
    .skltbs-panel {
        position: relative;
        transition: $duration;
        transition-property: opacity, transform;
    }

    .skltbs-leave {
        position: absolute;
        top: 0;
        @include force-visible;
        opacity: 1;
        transform: translate3d(0, 0, 0);

        &-active {
            opacity: 0;
            transform: translate3d(0, 50%, 0);
        }
    }

    .skltbs-enter {
        opacity: 0;
        transform: scale(0.5);

        &-active {
            opacity: 1;
            transform: scale(1);
        }
    }
}
