//
// Variables
// --------------------------------------------------
$cardgroup-gap: 1rem !default;

//
// CSS Variables
// --------------------------------------------------
:root {
    --cardgroup-gap: #{$cardgroup-gap};
    --cardgroup-columns: 1;
}

//
// Card Group Element
// --------------------------------------------------
.card-group-element {
    display: flex;
    flex-wrap: wrap;
    gap: var(--cardgroup-gap);
}
.card-group-element-item {
    width: calc((100% / var(--cardgroup-columns)) - (var(--cardgroup-gap) * (var(--cardgroup-columns) - 1)) / var(--cardgroup-columns));
    .card {
        height: 100%;
    }
    .card-body + .card-footer {
        padding-top: 0;
    }
    .card-footer {
        border-top: 0;
        background-color: transparent;
    }
}
.card-group-element-align-left {
    justify-content: flex-start;
}
.card-group-element-align-center {
    justify-content: center;
}
.card-group-element-align-right {
    justify-content: flex-end;
}
.card-group-element-columns-2 {
    @include respond-min($screen-xs) {
        --cardgroup-columns: 2;
    }
}
.card-group-element-columns-3 {
    @include tablet() {
        --cardgroup-columns: 3;
    }
}
.card-group-element-columns-4 {
    @include respond-min($screen-xs) {
        --cardgroup-columns: 2;
    }
    @include large() {
        --cardgroup-columns: 4;
    }
}


.card-group-element-item {
    .card {
        border: 1px solid $gray;
        display: flex;
        flex-direction: column;
        min-width: 0;
        position: relative;
        word-wrap: break-word;
    }
    .card-header,
    .card-footer {
        padding: 0.5rem 1rem;
    }
    .card-body {
        flex: 1 1 auto;
        padding: 1rem;
    }
    .card-title {
        margin-bottom: 0.5rem;
    }
}
