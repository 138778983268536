#kesearch_results {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 2rem);
  margin: 0 -1rem;

  .result-list-item {
    flex-basis: 100%;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 1rem !important;
    display: flex;
    flex-direction: column;

    display: flex;
    flex-direction: column;

    @include desktop() {
      flex-basis: 50%;
      width: 50%;

      // &.topnews {
      //   flex-basis: 100%;
      //   width: 100%;
      // }
    }

    .teaser_icon {
      order: -1;
      margin-bottom: 1rem;

      img {
        width: 100%;
      }
    }
  }

  .result-title {
    @extend h3;
    font-weight: bold;
    font-size: $fs-m !important;

    @include desktop() {
      font-size: $fs-l !important;
    }

    a {
      text-decoration: none;
    }
  }

  // ?
  .result-teaser {
    display: none;
  }
}
