/*! copyright @ xerc.de */

@mixin mobile-nav() {
  @media (max-width: $mobile-nav-breakpoint-min - 1px) {
    @content;
  }
}

@mixin desktop-nav() {
  @media (min-width: $mobile-nav-breakpoint-min) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: $tablet-breakpoint-min) {
    @content;
  }
}

@mixin desktop() {
  @media (min-width: $desktop-breakpoint-min) {
    @content;
  }
}

@mixin mobile-max() {
  @media (max-width: $tablet-breakpoint-min - 1px) {
    @content;
  }
}

@mixin tablet-max() {
  @media (max-width: $desktop-breakpoint-min - 1px) {
    @content;
  }
}

@mixin large() {
  @media (min-width: $large-breakpoint-min) {
    @content;
  }
}

@mixin respond-min($width) {
  @media (min-width: $width) {
    @content;
  }
}

@mixin respond-max($width) {
  @media (max-width: $width) {
    @content;
  }
}

@mixin hover() {
  &:focus,
  &:hover {
    @media (hover: hover) {
      @content;
    }
  }
  &:active {
    @media (hover: none) {
      @content;
    }
  }
}