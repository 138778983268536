@mixin button(
  $background: $black,
  $color: $white,
  $hover-background: $black,
  $hover-color: $white,
  $min-width: 275px,
  $min-height: 80px,
  $padding: .75rem 2rem,
  $font-size: $fs-m,
) {
  background-color: $background;
  color: $color;
  padding: $padding;
  text-align: center;
  transition: all 0.2s ease;
  cursor: pointer;
  min-width: $min-width;
  min-height: $min-height;
  display: inline-block;
  font-family: $headline-font;
  line-height: 1;
  border: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size;
  text-decoration: none;
  font-weight: $font-medium;

  &:hover {
    background-color: $hover-background;
    color: $hover-color;
  }
}

.button,
.button--primary {
  @include button(
    $background: $primary,
    $color: $white,
    $hover-background: lighten($primary, 10%),
    $hover-color: $white,
  );
}
.button--block {
  @include button(
    $background: $primary,
    $color: $white,
    $hover-background: lighten($primary, 10%),
    $hover-color: $white,
    $min-width: 100%,
  );
}
.button--secondary {
  @include button(
    $background: $secondary,
    $color: $textcolor,
    $hover-background: darken($secondary, 10%),
    $hover-color: $textcolor,
  );
}

@mixin arrow-right-icon($color) {
    // inspect returns the color as a string, if there is a "#" replace it with urlencoded value
    $stripped-color: str-replace(inspect($color), '#', '%23');
    background-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.4805 7.9274L11.4804 7.92744L11.4842 7.93665C11.5008 7.97722 11.5008 8.02274 11.4842 8.06331L11.4841 8.06328L11.4805 8.07256C11.473 8.09189 11.462 8.10964 11.448 8.12491L8.11978 11.4531L8.11978 11.4531L8.11833 11.4545C8.10284 11.4702 8.0844 11.4826 8.0641 11.491C8.04379 11.4995 8.022 11.5038 8 11.5038C7.978 11.5038 7.95621 11.4995 7.9359 11.491C7.9156 11.4826 7.89716 11.4702 7.88167 11.4545L7.88168 11.4545L7.87877 11.4516C7.86315 11.4362 7.85075 11.4177 7.84229 11.3974C7.83382 11.3771 7.82947 11.3553 7.82947 11.3333C7.82947 11.3113 7.83382 11.2895 7.84229 11.2692C7.85075 11.2489 7.86315 11.2305 7.87877 11.215L7.87968 11.2141L10.0797 9.02074L10.9364 8.16665H9.72667H4.66667C4.62246 8.16665 4.58007 8.14909 4.54882 8.11783C4.51756 8.08658 4.5 8.04418 4.5 7.99998C4.5 7.95578 4.51756 7.91339 4.54882 7.88213C4.58007 7.85087 4.62246 7.83331 4.66667 7.83331H9.72667H10.9364L10.0797 6.97922L7.88022 4.78643C7.88016 4.78637 7.8801 4.78631 7.88004 4.78625C7.84839 4.7545 7.83061 4.71149 7.83061 4.66665C7.83061 4.6444 7.83499 4.62237 7.8435 4.60182C7.85201 4.58127 7.86449 4.5626 7.88022 4.54687C7.91199 4.5151 7.95507 4.49725 8 4.49725C8.02224 4.49725 8.04427 4.50164 8.06482 4.51015L8.2551 4.05078L8.06483 4.51015C8.08538 4.51866 8.10405 4.53114 8.11978 4.54687L11.448 7.87506C11.462 7.89033 11.473 7.90808 11.4805 7.9274Z" fill="#{$stripped-color}" stroke="#{$stripped-color}"/></svg>');
}

@mixin download-icon($color) {
    // inspect returns the color as a string, if there is a "#" replace it with urlencoded value
    $stripped-color: str-replace(inspect($color), '#', '%23');
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 499.93"><path fill-rule="nonzero" fill="#{$stripped-color}" d="M114.51 278.73c-4.37-4.2-4.55-11.2-.38-15.62a10.862 10.862 0 0 1 15.46-.39l115.34 111.34V11.07C244.93 4.95 249.88 0 256 0c6.11 0 11.06 4.95 11.06 11.07v362.42L378.1 262.85c4.3-4.27 11.23-4.21 15.46.13 4.23 4.35 4.17 11.35-.13 15.62L264.71 406.85a11.015 11.015 0 0 1-8.71 4.25c-3.45 0-6.52-1.57-8.56-4.04L114.51 278.73zm375.35 110.71c0-6.11 4.96-11.07 11.07-11.07S512 383.33 512 389.44v99.42c0 6.12-4.96 11.07-11.07 11.07H11.07C4.95 499.93 0 494.98 0 488.86v-99.42c0-6.11 4.95-11.07 11.07-11.07 6.11 0 11.07 4.96 11.07 11.07v88.36h467.72v-88.36z"/></svg>');
}


@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);
    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }
    @return $string;
}
