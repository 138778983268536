.frame-layout {

  &-gray {
    background: $lightgray;

    svg {
      color: $turquoise;
    }
  }

  &-white {
    background: $white;
    color: $textcolor;

    svg {
      color: $turquoise;
    }
  }

  &-bluetrans {
    color: $white;
    background: rgba($blueblack, 0.9);

    svg {
      color: $white;
    }
  }

  &-bg-overlay {
    picture:after {
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(black, 0.5);
    }
  }

  &-bg-overlay-gradient {
    picture:after {
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(90deg,rgba(0,0,0,.7) 0%,rgba(0,0,0,0) 100%);
    }
  }

  &-blue {
    color: $white;
    background: $blueblack;

    svg {
      color: $white;
    }
  }

  &-mint {
    color: $white;
    background: $mint;

    svg {
      color: $white;
    }
  }

  &-shadow {
    box-shadow: 0px 3px 6px $gray;
    width: calc(100% - 1.25rem);
    height: 100%;

    svg {
      color: $turquoise;
    }
  }

  &-gray-cite,
  &-blue-cite {
    position: relative;

    &:before {
      content: "";
      width: 0px;
      height: 0px;
      position: absolute;
      border-left: 1.5rem solid transparent;
      border-right: 0.75rem solid transparent;
      border-top: 0.75rem solid transparent;
      border-bottom: 1.25rem solid transparent;
      right: 10%;
      top: 100%;
    }
  }

  &-gray-cite {
    background: $lightgray;
    margin-bottom: 2.5rem;
    color: $textcolor;

    &:before {
      border-left: 1.5rem solid $lightgray;
      border-top: 0.75rem solid $lightgray;
    }
  }

  &-blue-cite {
    background: $blueblack;
    color: $white;

    &:before {
      border-left: 1.5rem solid $blueblack;
      border-top: 0.75rem solid $blueblack;
    }
  }

  &-border {

    @include desktop-nav() {
      .col-3-lg,
      .col-4-lg,
      .col-6-lg {
        margin: 0;
        padding: 0.5rem 0;
      }
      .col-3-lg + .col-3-lg,
      .col-4-lg + .col-4-lg,
      .col-6-lg + .col-6-lg {
        border-left: 1px solid $gray;
      }
    }
  }

  &-blue-icon .ce-gallery svg {
    color: $blueblack;
  }

  &-gray-icon .ce-gallery svg {
    color: $gray;
  }

  &-kiwimint-icon .ce-gallery svg {
    color: $mint;
  }

  &-turquoise-icon .ce-gallery svg {
    color: $turquoise;
  }

  &-white-icon .ce-gallery svg {
    color: $white;
  }
}

.frame {

  &-slimmer {
    max-width: $container-width--slimmer;
  }

  &-full-width {
    max-width: none;

    [class*="col-"] {
      margin: 0;
    }
  }

  &-full-height {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    flex-direction: column;
    justify-content: center;
  }

  &-klapper {
    @include klapper($color: $mint);

    header h1,h2,h3,h3,h4,h5,h6 {
      margin: 0;

      &:first-child {
        font-weight: bold;
      }
    }
  }

  &-g-accordion {
    position: relative;
    overflow: hidden;
    transition: background-color 0.4s ease;

    color: $white;
    background: $blueblack;
    &:hover {
      background-color: $mint;
    }

    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }

    .ce-textpic {
      position:  absolute;
      top: 0;
      left: $frame-padding;
      bottom: 0;
      right: $frame-padding;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;

      @include desktop {
        max-width: 18vw;
      }
    }

    .ce-gallery {
      max-height: 500px;
      font-size: $fs-m;
      font-weight: normal;

      @include large {
        font-size: $fs-l;
      }

      .image {
        &:before {
          transition: border-color 0.4s ease;
          content: "";
          display: block;
          border: 2px solid;
          border-radius: 100%;
          width: 86px;
          height: 86px;
          position: absolute;
          left: 50%;
          transform: translate(-50%);
        }
      }

      svg {
        width: 90px;
        height: 90px;
        padding: 20px;
        transition: all .8s ease;
      }

      @include mobile-max {
        max-height: 500px;
        font-size: $fs-sm;
        font-weight: bold;

        .image {
          &:before {
            transition: all 0.4s ease;
            border-color: transparent;
          }
        }
      }
    }

    .ce-bodytext {
      max-height: 0;
      transition: max-height .3s ease;
      overflow: hidden;
      opacity: 0;

      @include mobile-max {
        max-height: 500px;
        opacity: 1;
      }

      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background: $white;
        top: 1px;
      }
    }

    @include tablet() {
      &.frame-layout-gray {
        svg {
          color: $blueblack;
        }
        &:hover {
          background-color: $blueblack;
          color: $white;

          svg {
            color: $white;
          }

          .ce-bodytext {
            &:before {
              background: $white;
            }
          }
        }

        .ce-bodytext {
          &:before {
            background: $blueblack;
          }
        }
      }

      &:hover {
        transition: background-color 0.4s ease;

        .ce-bodytext {
          transition: max-height 1s ease, opacity .5s ease 0.3s;
          max-height: 500px;
          opacity: 1;
        }

        .ce-gallery {
          max-height: 500px;
          font-size: $fs-sm;
          font-weight: bold;

          .image {
            &:before {
              transition: all 0.4s ease;
              border-color: transparent;
            }
          }

          svg {
            width: 110px;
            height: 110px;
          }
        }
      }
    }

    // mobile colors - hardcoded
    @include mobile-max() {
      .col-4-lg > & {
        background-color: $blueblack;
        color: $white;
      }
      .col-4-lg:nth-child(2) > & {
        background-color: $mint;
        color: $white;
        svg {
          color: $white;
        }
      }
    }
  }

  &-slider,
  &-slider2 {

    .slick-list {
      width: 100%;
    }

    .slick-slide {
      @include mobile-max {
        padding: 0 $frame-padding;
      }
    }
  }


  &-slider2 {
    display: flex;

    .slick-dots {
      order: -1;
    }
  }

  &-v-overflow {

    .col-6-lg > & {
      height: 100%;

      @include tablet-max() {
        min-height: MIN(400px, 100vw);
      }

      .ce-gallery,
      .ce-outer,
      .ce-inner {
        height: 100%;
      }

      .ce-inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    > * {
      @include tablet() {
        height: calc(100% + 4rem);
        top: -2rem;
        bottom: -2rem;
        width: 100%;
        position: relative;
      }
    }
  }

  &-line-dots2 &-v-overflow {
    min-height: 300px;

    @include desktop() {
      height: calc(100% + 8rem);
      top: -4rem;
      bottom: -4rem;
    }
  }

  &-line-dots {

    .ce-textpic {
      width: 100%;
    }

    @include desktop-nav() {

      .dot-frame.first:before {
        top: 50%;
      }
      .dot-frame.last:before {
        bottom: 50%;
      }

      .dot-frame {
        padding-left: 4rem;
        height: 100%;
        display: flex;
        align-items: center;
        position: unset;

        &:before {
          border-left: 1px solid $gray;
          content: "";
          position: absolute;
          top: 0;
          margin-left: -4rem;
          bottom: 0;
        }

        .dot {
          @include dot;
          top: 50%;
          margin-left: -4rem;
        }
      }

      .ce-gallery.animated {
        transform: scale(0);
        transition: transform 0.5s ease;

        &.active {
          transform: scale(1);
        }
      }
    }
  }


  &-line-dots2 {

    @include desktop-nav() {
      &:before {
        border-left: 1px solid $gray;
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 1;
        left: calc(50% - 5rem);
      }

      .dot-frame .col-6-lg:first-child .frame-type-text {
        padding-right: 7rem;
      }

      .dot-frame {

        .dot {
          @include dot;
          z-index: 2;
          left: 50%;
          top: 50%;
          left: calc(50% - 5rem);
        }
      }
    }
  }

  &-line-dots3 {

    @include desktop-nav() {

      .dot-frame {
        position: relative;

        &:not(.first):not(.last) {
          z-index: 1;
        }

        &:before {
          border-bottom: 2px solid $gray;
          content: "";
          position: absolute;
          top: 25%;
          left: 0;
          z-index: 0;
          width: 100%;
        }

        &:first-child:before {
          left: 50%;
        }

        &:last-child:before {
          left: auto;
          right: 50%;
        }

        .dot {
          @include dot($dynamic: false);
          top: 25%;
          z-index: 2;
          right: 0;
          transform: translate(50%, -50%);
        }

        &:last-child .dot {
          display: none;
        }
      }
    }
  }

  &-line-dots4 {

    @include desktop-nav() {
      &:before {
        border-left: 1px solid $gray;
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 1;
        left: 50%;
      }

      .dot-frame {

        .dot {
          @include dot;
          z-index: 2;
          left: 50%;
          top: 50%;
        }
      }
    }
  }

  &-flex-gallery {
    .ce-gallery {
      &[data-ce-columns="1"] .ce-row {
        max-width: 25%;

        @include desktop() {
          max-width: none;
        }
      }

      &.ce-border .image > * {
        box-shadow: 0px 3px 6px $gray;
      }
    }

    .ce-row {
      padding: .75rem;


      &.animated .ce-column {
        transform: translate(0, 100%);
        opacity: 0;
        transition-property: none;
      }

      &.animated.active .ce-column {
        opacity: 1;
        transform: translate(0, 0);
        transition-property: all;
        transition-duration: 0.5s;
        transition-timing-function: ease;
      }

      @for $i from 1 through 20 {
        &:nth-child(#{$i}n) .ce-column {
          transition-delay: #{$i * 0.1 + .1}s;
        }
      }
    }

    .ce-inner {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    a {
      display: block;
      height: 100%;
    }

    .ce-column {
      height: 100%;
      display: flex;
      align-items: center;

      .image > * {
        padding: 10%;
      }
    }
  }

  &-vw100 {
    width: 100vw;

    img,
    svg {
      width: MIN(calc(#{$container-width} - calc(#{$frame-padding} * 5)), 100vw);
      height: 120px;
    }

    @include desktop() {
      width: MIN(calc(#{$container-width} - calc(#{$frame-padding} * 5)), calc(100vw - calc(#{$frame-padding} * 5)));

      height: 3rem;
      position: relative;

      > * {
        position: absolute;
        bottom: 0;
      }
    }
  }

  &-scrollsection {
    > * {
      top: 72px;
      width: 100%;
      min-height: calc(100vh - 3rem - 72px);
      position: absolute;

      @include desktop-nav() {
        height: 100%;
        top: 94px;
        min-height: calc(100vh - 3rem - 94px);
        padding-bottom: 10vh;
      }
    }

    > [data-img-background] .contain object,
    > [data-img-background] .contain svg {
      max-width: 100%;
      left: 50%;
      transform: translate(-50%);
    }

    @include mobile-max() {
      font-size: 14px;

      h2 {
        font-size: 32px;
      }
      h3 {
        font-size: 28px;
      }
      h4 {
        font-size: 22px;
      }
    }
  }

}

// more frame padding
@include desktop-nav() {
  .frame {
    .col-3-lg > &-slim,
    .col-4-lg > &-slim,
    .col-6-lg > &-slim {
      padding-left: $frame-padding * 1.5;
      padding-right: $frame-padding * 1.5;
    }
    .col-3-lg > &-slimmer,
    .col-4-lg > &-slimmer,
    .col-6-lg > &-slimmer {
      padding-left: $frame-padding * 2.75;
      padding-right: $frame-padding * 2.75;
    }
  }
}

[class*=frame-] {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
