.ce-tile {
    a {
        text-decoration: none;
    }

    .ce-tile-image {
        img {
            display: block;
        }
    }

    .ce-tile-head {
        color: $mint;
        font-weight: bold;
        margin-top: 0.5rem;
    }

    .ce-tile-text {
        p {
            margin-top: 0;
        }
    }
}

